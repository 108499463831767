import { on, run } from '../helper/electron';
import isElectron from '../utils/isElectron';

const getIconPath = async (iconName: string) => {
  let assetPath = '';
  if (isElectron() && process.env.NODE_ENV === 'production') {
    run('get-asset-path', `icons/${iconName}`);

    return await new Promise<string>((resolve) => {
      on(`get-asset-path-icons/${iconName}`, (path) => {
        assetPath = path;
        resolve(assetPath);
      });
    });
  } else {
    assetPath = `/assets/icons/${iconName}`;
    return assetPath;
  }
};

// Export the icons as async getters
export const getIcons = async () => ({
  profileCircle: await getIconPath('profile-circle.svg'),
  playlist: await getIconPath('playlist.svg'),
  arrowCircleLeft: await getIconPath('arrow-circle-left.svg'),
  arrowCircleRight: await getIconPath('arrow-circle-right.svg'),
  arrowLeft: await getIconPath('arrow-left.svg'),
  home: await getIconPath('home.svg'),
  allCrates: await getIconPath('all-crates.svg'),
  charts: await getIconPath('charts.svg'),
  myCrates: await getIconPath('my-crates.svg'),
  myMusic: await getIconPath('my-music.svg'),
  genres: await getIconPath('genres.svg'),
  hacks: await getIconPath('hacks.svg'),
  vipCrates: await getIconPath('vip-crates.svg'),
  spotify: await getIconPath('spotify.svg'),
  spotifyIcon: await getIconPath('spotify-icon-green.png'),
  spotifyLogo: await getIconPath('spotify-logo-green.png'),
  textImport: await getIconPath('text-import.svg'),
  shazamToCrate: await getIconPath('shazam-to-crate.svg'),
  folderFlattener: await getIconPath('folder-flattener.svg'),
  marketplace: await getIconPath('text-import.svg'),
  smplLogo: await getIconPath('smpllogo.svg'),
  djepLogo: await getIconPath('djeplogo.svg'),
  futureDJLogo: await getIconPath('future-dj-logo.svg'),
  cloudCrates: await getIconPath('cloud-crates.svg'),
  spotifyFull: await getIconPath('spotify-full.svg'),
  apple: await getIconPath('apple.svg'),
  soundcloud: await getIconPath('soundcloud.svg'),
  tidal: await getIconPath('tidal.svg'),
  deezer: await getIconPath('deezer.svg'),
  beatsource: await getIconPath('beatsource.svg'),
  avatar: await getIconPath('avatar.svg'),
  whiteTrash: await getIconPath('white-trash.svg'),
  redTrash: await getIconPath('red-trash.svg'),
  arrowForward: await getIconPath('arrow-forward-outline'),
  arrowBackward: await getIconPath('arrow-back-outline'),
  playButton: await getIconPath('play-button.svg'),
  pauseButton: await getIconPath('pause-button.svg'),
  flameLogo: await getIconPath('icon.png'),
  seratoDJPro: await getIconPath('serato-dj-pro.svg'),
  rotateLeft: await getIconPath('rotate-left.svg'),
  uploadCloud: await getIconPath('upload-cloud.svg'),
  search: await getIconPath('search.svg'),
  plus: await getIconPath('plus.svg'),
  folders: await getIconPath('folders.svg'),
  bangerButtonLogo: await getIconPath('banger-button-logo.svg'),
  magicPlaylistLogo: await getIconPath('magic-playlist-logo.svg'),
  djsVaultLogo: await getIconPath('djs-vault-logo.svg'),
  dangerousDJsLogo: await getIconPath('dangerous-djs-logo.svg'),
  chLogoLightMode: await getIconPath('logo.png'),
  chLogoDarkMode: await getIconPath('logo-dark.svg'),
  pdfApple: await getIconPath('pdfCloudCratePNGS/Apple.png'),
  pdfBeatsource: await getIconPath('pdfCloudCratePNGS/Beatsource.png'),
  pdfDeezer: await getIconPath('pdfCloudCratePNGS/Deezer.png'),
  pdfSoundcloud: await getIconPath('pdfCloudCratePNGS/Soundcloud.png'),
  pdfSpotify: await getIconPath('pdfCloudCratePNGS/Spotify.png'),
  pdfTidal: await getIconPath('pdfCloudCratePNGS/Tidal.png'),
  pdfCloudCrates: await getIconPath('pdfCloudCratePNGS/CloudCrates.png'),
});
