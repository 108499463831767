/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationScopesFromJSON, ApplicationScopesToJSON, } from './ApplicationScopes';
import { ModificationActionsFromJSON, ModificationActionsToJSON, } from './ModificationActions';
/**
 * Check if a given object implements the ApplicationModificationDto interface.
 */
export function instanceOfApplicationModificationDto(value) {
    if (!('applicationId' in value) || value['applicationId'] === undefined)
        return false;
    if (!('modification' in value) || value['modification'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('scopes' in value) || value['scopes'] === undefined)
        return false;
    return true;
}
export function ApplicationModificationDtoFromJSON(json) {
    return ApplicationModificationDtoFromJSONTyped(json, false);
}
export function ApplicationModificationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'applicationId': json['applicationId'],
        'modification': ModificationActionsFromJSON(json['modification']),
        'status': json['status'],
        'scopes': ApplicationScopesFromJSON(json['scopes']),
    };
}
export function ApplicationModificationDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'applicationId': value['applicationId'],
        'modification': ModificationActionsToJSON(value['modification']),
        'status': value['status'],
        'scopes': ApplicationScopesToJSON(value['scopes']),
    };
}
