import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRange,
  IonRow,
  IonToggle,
} from '@ionic/react';
import { eyeOff, funnel, funnelOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from '../../../context/breakpoint/BreakpointContext';
import {
  selectCrateState,
  selectFilteredCrateTracks,
  setCrateFilterActive,
  updateCrateFilterValue,
} from '../../../store/slices/crate.slice';
import { AppDispatch } from '../../../store/store';
import { iTrack } from '../../../types/ITrack';
import MagicSorter from '../MagicSorter/MagicSorter';
import style from './style.module.scss';
import { CRATE_FILTER_TYPES, CRATE_FILTERS } from './types';

export const CrateFilter = ({
  onShowFilteredTracks,
  onMagicSort,
}: {
  onShowFilteredTracks: () => void;
  onMagicSort: (tracks: iTrack[]) => void;
}) => {
  const { matchesMediumDown } = useBreakpoint();
  const dispatch = useDispatch<AppDispatch>();
  const {
    filter: { isFilterActive, isFilterable, filters },
    isSortable,
  } = useSelector(selectCrateState);
  const { hidden, visible: filteredTracks } = useSelector(
    selectFilteredCrateTracks,
  );

  return isFilterable ? (
    <IonRow
      className={style['filter-slider-row' + (isFilterActive ? '-active' : '')]}
    >
      <IonRow className={style['filter-range-row']}>
        <div>
          <IonRow>
            <IonToggle
              className={style['filter-toggle']}
              checked={isFilterActive}
              onIonChange={(e) =>
                dispatch(setCrateFilterActive(e.detail.checked))
              }
              labelPlacement={matchesMediumDown ? 'stacked' : 'end'}
            >
              <div className="ion-hide-md-down">Enable Filtering</div>
              <div className="ion-hide-md-up">
                <IonIcon
                  size="large"
                  color={isFilterActive ? 'primary' : undefined}
                  icon={isFilterActive ? funnel : funnelOutline}
                />
              </div>
            </IonToggle>
          </IonRow>
          {hidden?.length ? (
            <IonRow style={{ position: 'relative' }}>
              <IonButton
                className={style['hidden-track-button']}
                size="small"
                fill="clear"
                onClick={onShowFilteredTracks}
              >
                <IonIcon slot="start" icon={eyeOff} />
                {hidden?.length} hidden track{hidden?.length === 1 ? '' : 's'}
              </IonButton>
            </IonRow>
          ) : null}
        </div>
        {CRATE_FILTER_TYPES.map((filterType) => (
          <IonCol key={filterType}>
            <IonGrid>
              <IonRow>
                <IonRange
                  className={style['filter-range']}
                  disabled={!isFilterActive}
                  dualKnobs={true}
                  value={filters[filterType]}
                  pin={true}
                  label={CRATE_FILTERS[filterType]}
                  labelPlacement={matchesMediumDown ? 'stacked' : 'start'}
                  onIonInput={(event: any) =>
                    dispatch(
                      updateCrateFilterValue({
                        filter: filterType,
                        value: {
                          lower: event.detail.value.lower,
                          upper: event.detail.value.upper,
                        },
                      }),
                    )
                  }
                />
              </IonRow>
            </IonGrid>
          </IonCol>
        ))}
        <MagicSorter
          tracks={filteredTracks}
          linkedTracks={[]}
          onSortChange={onMagicSort}
          isSortable={isSortable}
        />
      </IonRow>
    </IonRow>
  ) : null;
};
