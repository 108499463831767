import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiService from '../../services/Api.service';
import { iTrack } from '../../types/ITrack';

interface TrackState {
  selectedTrack: iTrack | null;
}

const initialState: TrackState = {
  selectedTrack: null,
};

export const fetchTrackById = createAsyncThunk(
  'track/fetchTrackById',
  async (id: string) => await apiService.getTrackById(id),
);

export const TrackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setSelectedTrack: (state, action: PayloadAction<iTrack>) => {
      state.selectedTrack = action.payload;
    },
    clearTrack: (state) => {
      state.selectedTrack = null;
    },
  },
});

export const { setSelectedTrack, clearTrack } = TrackSlice.actions;

export default TrackSlice.reducer;
