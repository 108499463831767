import { IonSpinner } from '@ionic/react';
import style from './style.module.scss';

/**
 * Shows a non-obstructive spinner at the top of the page to indicate that an API call is in progress
 */
export const SavingSpinner = ({
  isOpen,
  label = 'Saving changes',
}: {
  isOpen: boolean;
  label?: string;
}) =>
  isOpen ? (
    <div className={style.savingSpinner}>
      <IonSpinner name="crescent" />
      {label}
    </div>
  ) : null;
