import { IonText } from '@ionic/react';
import React from 'react';
import { iHackers } from '../../types/IHackers';
import './Hackers.scss';

export const HackersInline: React.FC<iHackers> = ({ id, hackers }) => {
  return (
    <IonText>
      {hackers &&
        hackers.length > 0 &&
        hackers.map((hacker, index) => (
          // <IonRouterLink
          //   key={'hacker-' + hacker._id}
          //   href={'/hacker/' + hacker._id}
          // >
          <React.Fragment key={index}>
            {hacker.name}
            {index < hackers.length - 1 && ', '}
          </React.Fragment>
          // </IonRouterLink>
        ))}
    </IonText>
  );
};
