import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Global loading state in the app. Avoids needing to re-create this logic for every page
 */
export const LoadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => {
      state.isLoading = isLoading;
    },
  },
});

export const selectIsLoading = (state: { loading: { isLoading: boolean } }) =>
  state.loading.isLoading;

export const { setIsLoading } = LoadingSlice.actions;

export default LoadingSlice.reducer;
