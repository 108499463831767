import {
  DefaultApi,
  Configuration,
  TrackAvailabilityOptionsDto,
} from '@cratehackers/api-client';
import store from '../store/store';
import { AppIdentifiers } from '../store/slices/token-state.slice';
import { config } from '../config';
import { iTrack } from '../types/ITrack';

class ApiService {
  private static instance: DefaultApi;

  private static getAuthToken(): string {
    const appToken = store.getState().tokenState[AppIdentifiers.App];
    return appToken && appToken.accessToken ? appToken.accessToken : '';
  }

  public static getApiClient(): DefaultApi {
    const apiConfig = new Configuration({
      basePath: config.API_BASE_URL,
      accessToken: ApiService.getAuthToken(),
    });
    this.instance = new DefaultApi(apiConfig);
    return this.instance;
  }

  public static async getTrackAvailabilityOptions() {
    const apiClient = ApiService.getApiClient();
    const data: TrackAvailabilityOptionsDto =
      await apiClient.trackControllerGetTrackAvailabilityOptions();

    return data;
  }

  private static mapToITrack = (trackSearchDto: any): iTrack => {
    return {
      ID: trackSearchDto.uuid,
      Artist: trackSearchDto.artist?.name,
      Title: trackSearchDto.title || '',
      AlbumCover: trackSearchDto.album?.picture,
      BPM: Math.round(trackSearchDto.bpm) || -1,
      energy: Math.round(trackSearchDto.energy * 100) || undefined,
      danceability: Math.round(trackSearchDto.danceability * 100) || undefined,
      valence: Math.round(trackSearchDto.valence * 100) || undefined,
      popularity: trackSearchDto.popularity || undefined,
      Key_Camelot: trackSearchDto.key || '',
    };
  };

  public static async getTrackById(trackId: string) {
    try {
      const api = ApiService.getApiClient();
      const track = await api.trackControllerGetTrack({ id: trackId });
      const mappedTrack = ApiService.mapToITrack(track);
      console.log('mappedTrack:', mappedTrack);

      return mappedTrack;
    } catch (error) {
      console.error('Error fetching track by ID:', error);
      return null;
    }
  }
}

export default ApiService;
