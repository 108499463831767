import { IonToast } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeToast,
  selectCurrentToast,
} from '../../store/slices/toast.slice';

const DEFAULT_TOAST_TIMEOUT = 3000;

/**
 * Displays toasts at a global level, can be triggered via the useToast hook
 */
export const ToastManager = () => {
  const currentToast = useSelector(selectCurrentToast);
  const dispatch = useDispatch();

  return (
    currentToast && (
      <IonToast
        key={currentToast.id}
        isOpen={true}
        onDidDismiss={() => dispatch(removeToast())}
        message={currentToast.message}
        color={currentToast.variant}
        position={currentToast.position ?? 'bottom'}
        animated={true}
        buttons={currentToast.isDismissible ? ['Close'] : undefined}
        duration={
          currentToast.isDismissible
            ? undefined // If the toast is dismissible, unlimited timeout
            : (currentToast.duration ?? DEFAULT_TOAST_TIMEOUT) // Otherwise it's configurable
        }
        className={'ch-toast'}
      />
    )
  );
};
