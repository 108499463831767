/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CrateImageUrlDto interface.
 */
export function instanceOfCrateImageUrlDto(value) {
    if (!('imageUrl' in value) || value['imageUrl'] === undefined)
        return false;
    return true;
}
export function CrateImageUrlDtoFromJSON(json) {
    return CrateImageUrlDtoFromJSONTyped(json, false);
}
export function CrateImageUrlDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'imageUrl': json['imageUrl'],
    };
}
export function CrateImageUrlDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'imageUrl': value['imageUrl'],
    };
}
