/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationScopesFromJSON, ApplicationScopesToJSON, } from './ApplicationScopes';
/**
 * Check if a given object implements the ApiApplication interface.
 */
export function instanceOfApiApplication(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('scopes' in value) || value['scopes'] === undefined)
        return false;
    return true;
}
export function ApiApplicationFromJSON(json) {
    return ApiApplicationFromJSONTyped(json, false);
}
export function ApiApplicationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'email': json['email'],
        'scopes': ApplicationScopesFromJSON(json['scopes']),
    };
}
export function ApiApplicationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'email': value['email'],
        'scopes': ApplicationScopesToJSON(value['scopes']),
    };
}
