import { find } from 'lodash';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BreakPoint,
  BREAKPOINT_KEYS,
  BreakpointContextProps,
  BREAKPOINTS,
} from './types';

const BreakpointContext = createContext<BreakpointContextProps>({
  currentBreakpoint: undefined,
  matchesSmall: false,
  matchesMediumDown: false,
  matchesMediumUp: false,
  matchesLargeDown: false,
  matchesLargeUp: false,
  matchesXL: false,
});

/**
 * Context allows us to only measure breakpoint once globally but consume it throughout the app in multiple places
 */
export const BreakpointContextProvider = ({ children }: PropsWithChildren) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<BreakPoint>();

  useEffect(() => {
    const setBreakpoint = () => setCurrentBreakpoint(getBreakpoint());

    setBreakpoint();
    window.addEventListener('resize', setBreakpoint);

    return () => window.removeEventListener('resize', setBreakpoint);
  }, []);

  return (
    <BreakpointContext.Provider
      value={{
        currentBreakpoint,
        // Utility properties for simple display logic
        matchesSmall: currentBreakpoint === 'small',
        matchesMediumDown:
          currentBreakpoint === 'medium' || currentBreakpoint === 'small',
        matchesMediumUp: currentBreakpoint !== 'small',
        matchesLargeDown: currentBreakpoint !== 'xl',
        matchesLargeUp:
          currentBreakpoint === 'large' || currentBreakpoint === 'xl',
        matchesXL: currentBreakpoint === 'xl',
      }}
    >
      {children}
    </BreakpointContext.Provider>
  );
};

/**
 * Utility hook to check the current breakpoint, can be used for functional changes not necessarily possible with media queries alone
 */
export const useBreakpoint = () => useContext(BreakpointContext);

// Gets the current screen-size breakpoint
const getBreakpoint = (): BreakPoint =>
  find(
    BREAKPOINT_KEYS,
    (breakpoint) => window.innerWidth < BREAKPOINTS[breakpoint],
  ) ?? 'xl'; // If the screen size is not less than any of the breakpoints then it's XL
