/**
 * Custom errors to be thrown in the app, add to this union type any new custom errors
 */
type Errors = NotFoundError;

/**
 * Error to be thrown when something is not found
 */
export class NotFoundError extends Error {
  cause = 'not_found';
}

/**
 * Try/catch will always result in unknown errors. This function can be used to check if an error is of a custom type and will return the correctly typed object
 */
export const isError = <T extends Errors>(
  error: unknown,
  errorType: T,
): error is T => error instanceof Error && error.cause === errorType.cause;
