import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { debounce, groupBy, remove, uniqBy } from 'lodash';
import {
  CRATE_FILTER_TYPES,
  CrateFilter,
  CrateFilterRange,
  CrateFilterType,
} from '../../components/crates/CrateFilter/types';
import { config } from '../../config';
import ApiService from '../../services/Api.service';
import { CrateService } from '../../services/Crate.service';
import RequestService from '../../services/Request.service';
import { NotFoundError } from '../../types/Errors';
import { iCategory } from '../../types/ICategory';
import { iCrate } from '../../types/ICrate';
import { iTrack } from '../../types/ITrack';
import { generateTrackID } from '../../utils/generateTrackID';
import { RootState } from '../store';

type CrateState = {
  selectedCrate: iCrate | null;
  createdFrom: string;
  draft: boolean;
  currentRequestId: string | null;
  error: unknown;
  isSortable: boolean;
  isEditable: boolean;
  filter: {
    isFilterable: boolean;
    isFilterActive: boolean;
    filters: CrateFilter;
  };
};

const initialState: CrateState = {
  selectedCrate: null,
  createdFrom: '',
  draft: false,
  currentRequestId: null,
  error: null,
  isSortable: false,
  isEditable: false,
  filter: {
    isFilterable: false,
    isFilterActive: false,
    filters: {
      energy: { lower: 40, upper: 100 },
      valence: { lower: 40, upper: 100 },
      danceability: { lower: 60, upper: 100 },
    },
  },
};

/**
 * Retrieves crate from API via ID
 */
export const fetchCrateById = createAsyncThunk(
  'crates/fetchCratesById',
  async (crateId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = `${config.API_BASE_URL}/crates/${crateId}`;
      const data: iCrate = await RequestService.fetch({ url });
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

/**
 * Uploads an image to the API and returns a url to access the image
 */
export const uploadCrateImage = createAsyncThunk(
  'crates/uploadCrateImage',
  async (crateImageFile: File, { rejectWithValue, fulfillWithValue }) => {
    try {
      const chAPI = ApiService.getApiClient();
      const response = await chAPI.crateControllerUploadCrateImage({
        file: crateImageFile,
      });
      return fulfillWithValue(response.imageUrl);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Debounced functions ensures we don't make too many API requests at once to save crate changes.
 * State changes will occur immediately in Redux, but the API requests will only be sent after there has been 3 seconds with no additional requests to save
 */
const debouncedSaveCrateChanges = debounce(
  async (requestId: string, thunkAPI: GetThunkAPI<{ state: RootState }>) =>
    thunkAPI.dispatch(saveCrateChangesToApi(requestId)),
  3000,
  {
    leading: true, // Leading ensures that we don't need to wait 3 seconds for the initial API request, it will be sent immediately
  },
);

/**
 * Persists the current crate state to the API.
 * Uses the debounced function above to only send requests every 3 seconds.
 */
export const saveCrateChanges = createAsyncThunk(
  'crates/saveCrateChanges',
  async (_: undefined, thunkAPI: GetThunkAPI<{ state: RootState }>) => {
    // Calls to the API are debounced
    debouncedSaveCrateChanges(thunkAPI.requestId, thunkAPI);
    // However, we update the current request ID without a debounce.
    // This ensures that we only set isSaving=false when the most recent request has been fulfilled
    thunkAPI.dispatch(setCurrentRequestId(thunkAPI.requestId));
  },
);

/**
 * Persists the current crate state to the API. Without any of the current request ID and debounce logic
 */
const saveCrateChangesToApi = createAsyncThunk(
  'crates/saveCrateChangesToApi',
  async (
    originalRequestId: string, // We have to pass the original request ID since it originates from a different thunk and this one will have a different request ID
    thunkAPI: GetThunkAPI<{ state: RootState }>,
  ) => {
    const { selectedCrate } = thunkAPI.getState().crate;

    // Can only save user crates //TODO: This will need to change. Not only user crates anymore
    if (selectedCrate?.Type !== 'User' || !selectedCrate?._id) {
      return;
    }

    try {
      const updatedCrate = await CrateService.updateMyCrate(selectedCrate);
      return thunkAPI.fulfillWithValue({ originalRequestId, updatedCrate });
    } catch (error) {
      return thunkAPI.rejectWithValue({ originalRequestId, error });
    }
  },
);

export const CrateSlice = createSlice({
  name: 'crate',
  initialState,
  reducers: {
    setSelectedCrate: (state, action: PayloadAction<iCrate>) => {
      // Reset sortable and filterable flags
      state.isSortable = false;
      state.filter.isFilterable = false;

      // Reset hidden tracks when a new crate is selected
      state.filter.isFilterActive = false;
      state.filter.filters = initialState.filter.filters;

      state.selectedCrate = action.payload;
      if (action.payload.Tracks) {
        CrateSlice.caseReducers.setCrateTracks(state, {
          type: 'setCrateTracks',
          payload: action.payload.Tracks,
        });
      }
      state.isEditable = state.selectedCrate.editable || false;
    },

    setCrateEditable: (state, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload;
    },
    setCrateFilterActive: (state, action: PayloadAction<boolean>) => {
      state.filter.isFilterActive = action.payload;
    },

    updateCrateFilterValue: (
      state,
      action: PayloadAction<{
        filter: CrateFilterType;
        value: CrateFilterRange;
      }>,
    ) => {
      const { filter, value } = action.payload;
      state.filter.filters[filter] = value;
    },

    setCrateTracks: (state, { payload }: PayloadAction<iTrack[]>) => {
      // uniqBy ensures we never load duplicate tracks
      const tracks = uniqBy(
        payload.map((track) => {
          // Check if any tracks are sortable or filterable to set in state
          // Done inside of map to avoid having to iterate through tracks array multiple times
          applyTrackProperties(track, state);
          return { ...track, ID: generateTrackID(track) };
        }),
        'ID',
      );
      if (state.selectedCrate) {
        state.selectedCrate.Tracks = tracks;
      } else {
        state.selectedCrate = {
          // TODO: Framework for saving draft later
          Tracks: tracks,
          Title: 'Draft Crate',
          editable: true,
          _id: 'draft',
        };
      }
    },
    setTrackField: (
      state,
      action: PayloadAction<{
        trackId: string;
        field: keyof iTrack;
        value: any;
      }>,
    ) => {
      if (!state.selectedCrate?.Tracks) return;

      // Find the track and update the specific field
      const track = state.selectedCrate.Tracks.find(
        (t) => t.ID === action.payload.trackId,
      );
      if (track) {
        track[action.payload.field] = action.payload.value;
      }
    },

    addTrackToCrate: (state, { payload: track }: PayloadAction<iTrack>) => {
      const { selectedCrate } = state;
      if (!selectedCrate) {
        return;
      }
      const trackId = generateTrackID(track);
      if (selectedCrate.Tracks) {
        // If track already exists in crate, return and don't add it
        if (selectedCrate.Tracks.find((track) => track.ID === trackId)) {
          return;
        }

        selectedCrate.Tracks.push(track);
      } else {
        selectedCrate.Tracks = [track];
      }

      // Check if newly added track changes crate properties
      applyTrackProperties(track, state);
    },

    removeTrackFromCrate: (
      { selectedCrate },
      { payload: trackId }: PayloadAction<string>,
    ) => {
      if (!selectedCrate?.Tracks) {
        return;
      }
      const removedTracks = remove(
        selectedCrate.Tracks,
        (track) => track.ID === trackId,
      );

      if (!removedTracks.length) {
        throw new NotFoundError(
          `Track: ${trackId} does not exist on Crate ${selectedCrate._id}`,
        );
      }
    },

    setSelectedCrateName: (state, action: PayloadAction<string>) => {
      if (state.selectedCrate) {
        state.selectedCrate.Title = action.payload;
      } else {
        state.selectedCrate = {
          _id: 'draft',
          Title: action.payload,
          editable: true,
        };
      }
    },

    setSelectedCrateDescription: (state, action: PayloadAction<string>) => {
      if (state.selectedCrate) {
        state.selectedCrate.Description = action.payload;
      } else {
        throw new ReferenceError('There is no current crate');
      }
    },

    setSelectedCrateGroupId: (state, action: PayloadAction<number>) => {
      if (state.selectedCrate) {
        state.selectedCrate.groupId = action.payload;
      } else {
        throw new ReferenceError('There is no current crate');
      }
    },
    setSelectedCrateStreamingLinks: (
      state,
      { payload: links }: PayloadAction<Partial<iCrate>>,
    ) => {
      if (state.selectedCrate) {
        state.selectedCrate = { ...state.selectedCrate, ...links };
      } else {
        throw new ReferenceError('There is no current crate');
      }
    },

    setSelectedCrateCategories: (
      state,
      { payload: categories }: PayloadAction<iCategory[]>,
    ) => {
      if (state.selectedCrate) {
        state.selectedCrate.Category = categories;
      }
    },

    setCreatedFrom: (state, action: PayloadAction<string>) => {
      state.createdFrom = action.payload;
    },

    setCurrentRequestId: (state, action: PayloadAction<string | null>) => {
      state.currentRequestId = action.payload;
    },

    clearCrate: (state) => {
      state.selectedCrate = null;
      state.isEditable = false;
      state.createdFrom = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveCrateChangesToApi.fulfilled, (state, action) => {
      const { originalRequestId } = action.payload as {
        originalRequestId: string;
      };
      if (state.currentRequestId === originalRequestId) {
        state.currentRequestId = null;
        state.error = null;
      }
    });
    builder.addCase(saveCrateChangesToApi.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = null;
        state.error = action.error;
      }
    });
    builder.addCase(uploadCrateImage.fulfilled, (state, action) => {
      if (state.selectedCrate) {
        state.selectedCrate.Image = action.payload;
      }
    });

    builder.addCase(uploadCrateImage.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

const isTrackSortable = (track: iTrack) => !!(track.BPM && track.Key_Camelot);
const isTrackFilterable = (track: iTrack) =>
  !!(track.energy || track.danceability || track.popularity || track.valence);

/**
 * Sets crate's filterability of sortability based on a track's properties within it
 */
const applyTrackProperties = (track: iTrack, state: Draft<CrateState>) => {
  // A crate is sortable or filterable as long as a single track is sortable or filterable
  state.filter.isFilterable =
    state.filter.isFilterable || isTrackFilterable(track);
  state.isSortable = state.isSortable || isTrackSortable(track);
};

export const {
  setSelectedCrate,
  setCrateEditable,
  setCreatedFrom,
  setTrackField,
  clearCrate,
  setSelectedCrateName,
  setSelectedCrateDescription,
  setSelectedCrateGroupId,
  setSelectedCrateStreamingLinks,
  setSelectedCrateCategories,
  setCrateTracks,
  addTrackToCrate,
  removeTrackFromCrate,
  setCurrentRequestId,
  setCrateFilterActive,
  updateCrateFilterValue,
} = CrateSlice.actions;

export const selectSelectedCrate = ({ crate }: { crate: CrateState }) =>
  crate.selectedCrate;

export const selectCreatedFrom = ({ crate }: { crate: CrateState }) =>
  crate.createdFrom;

export const selectCrateName = ({ crate }: { crate: CrateState }) =>
  crate.selectedCrate?.Title;

export const selectCrateState = ({ crate }: { crate: CrateState }) => crate;

export const selectAllCrateTracks = ({ crate }: { crate: CrateState }) =>
  crate.selectedCrate?.Tracks;

export const selectFilteredCrateTracks = ({
  crate,
}: {
  crate: CrateState;
}): { visible: iTrack[]; hidden: iTrack[] } => {
  const allTracks = crate.selectedCrate?.Tracks;
  const { filters } = crate.filter;
  if (allTracks) {
    if (crate.filter.isFilterActive) {
      const groupedTracks = groupBy(allTracks, (track) => {
        const isVisible = CRATE_FILTER_TYPES.every((filter) => {
          const filterValue = track[filter];
          if (!filterValue) {
            return true;
          }

          return (
            filterValue >= filters[filter].lower &&
            filterValue <= filters[filter].upper
          );
        });

        return isVisible ? 'visible' : 'hidden';
      });
      return {
        visible: groupedTracks.visible ?? [],
        hidden: groupedTracks.hidden ?? [],
      };
    } else {
      return { visible: allTracks, hidden: [] };
    }
  }
  return { visible: [], hidden: [] };
};

export const selectIsCrateSaving = (state: { crate: CrateState }) =>
  !!state.crate.currentRequestId; // the crate is saving if there is any request in progress

export default CrateSlice.reducer;
