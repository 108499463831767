import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveCrateChanges,
  selectCrateState,
  setTrackField,
} from '../../store/slices/crate.slice';
import { AppDispatch } from '../../store/store';
import { useToast } from '../ToastManager/useToast';

interface EditableBPMFieldProps {
  trackId: string;
  bpmVal: number | undefined;
  className?: string;
  textColor?: string;
}

export const EditableBPMField: React.FC<EditableBPMFieldProps> = ({
  trackId,
  bpmVal,
  className = '',
  textColor = 'dark',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isEditable } = useSelector(selectCrateState);
  const [value, setValue] = useState<number | undefined>(bpmVal);
  const { errorToast } = useToast();

  const handleChange = async (e: CustomEvent<{ value: string | null }>) => {
    const newValue = e.detail.value ?? '';

    // Allow empty value (clears BPM)
    if (newValue.trim() === '') {
      setValue(undefined);
      dispatch(setTrackField({ trackId, field: 'BPM', value: undefined }));
      await dispatch(saveCrateChanges());
      return;
    }

    // Validate decimal input (only numbers and a single dot allowed)
    if (!/^\d*\.?\d*$/.test(newValue)) {
      errorToast({ message: 'BPM must be a valid number' });
      return;
    }

    const numValue = parseFloat(newValue);
    if (isNaN(numValue) || numValue <= 0 || numValue > 400) {
      errorToast({
        message: 'BPM must be a positive number and less than or equal to 400',
      });
      return;
    }

    if (numValue !== value) {
      setValue(numValue);
      dispatch(setTrackField({ trackId, field: 'BPM', value: numValue }));
      await dispatch(saveCrateChanges());
    }
  };

  const handleDouble = () => {
    if (!value) return;
    const newBPM = Number((value * 2).toFixed(2));
    if (newBPM > 400) {
      errorToast({ message: 'BPM cannot exceed 400' });
      return;
    }
    if (newBPM !== value) {
      setValue(newBPM);
      dispatch(setTrackField({ trackId, field: 'BPM', value: newBPM }));
      dispatch(saveCrateChanges());
    }
  };

  const handleHalve = () => {
    if (!value) return;
    const newBPM = Number((value / 2).toFixed(2));
    if (newBPM < 0) {
      errorToast({ message: 'BPM cannot be less than 0' });
      return;
    }
    if (newBPM !== value) {
      setValue(newBPM);
      dispatch(setTrackField({ trackId, field: 'BPM', value: newBPM }));
      dispatch(saveCrateChanges());
    }
  };

  return (
    <IonRow
      className={className}
      style={{ width: '100%', alignItems: 'center' }}
    >
      <IonCol size="12">
        {isEditable ? (
          <IonItem lines="none">
            {bpmVal !== undefined && (
              <IonButton
                fill="clear"
                slot="start"
                onClick={handleHalve}
                title="Halve BPM"
                style={{ marginRight: '10px' }}
              >
                ÷2
              </IonButton>
            )}

            <IonInput
              type="text"
              inputmode="decimal"
              pattern="^\d*\.?\d*$"
              value={value ?? ''}
              onIonInput={(e) =>
                handleChange(e as CustomEvent<{ value: string | null }>)
              }
              className={className}
              color={textColor}
              debounce={100}
              style={{
                textAlign: 'center',
                flex: 1,
                minWidth: '6ch',
                maxWidth: '9ch',
                MozAppearance: 'textfield',
                WebkitAppearance: 'none',
              }}
            />

            {bpmVal !== undefined && (
              <IonButton
                fill="clear"
                slot="end"
                onClick={handleDouble}
                title="Double BPM"
                style={{ marginLeft: '10px' }}
              >
                ×2
              </IonButton>
            )}
          </IonItem>
        ) : (
          <IonText color={textColor}>
            <p style={{ textAlign: 'center' }}>{value ?? '--'}</p>
          </IonText>
        )}
      </IonCol>
    </IonRow>
  );
};
