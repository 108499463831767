import {
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonModal,
  IonPopover,
  IonText,
} from '@ionic/react';
import { pencil } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImage } from '../../../utils/imageUtils';

import '../Crates.scss';

interface ImageCropperProps {
  editable: boolean;
  currentImageUrl?: string;
  onImageChange: (croppedFile: File | null) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  editable,
  currentImageUrl,
  onImageChange,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    currentImageUrl || undefined,
  );
  const [showCropModal, setShowCropModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropAreaPixels, setCropAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [originalImage, setOriginalImage] = useState<string | undefined>(
    undefined,
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const objectUrl = URL.createObjectURL(file);
    setOriginalImage(undefined);

    // Preload image to ensure it's ready before updating the state
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = objectUrl;
    img.onload = () => {
      setOriginalImage(objectUrl);
      setShowCropModal(true);
      setShowMenu(false);
    };

    // In case there's an error loading the image
    img.onerror = (event) => {
      console.error('❌ Error loading image preview:', img.src);
      setOriginalImage(undefined);
    };
  };

  const handleCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCropAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    if (!originalImage) return;

    try {
      if (!cropAreaPixels) return;
      const croppedFile = await getCroppedImage(originalImage, cropAreaPixels);

      if (!croppedFile || croppedFile.size === 0) {
        console.error('Cropped image is empty or invalid');
        return;
      }

      const previewUrl = URL.createObjectURL(croppedFile);

      setImageFile(croppedFile);
      setPreviewImage(previewUrl);
      setShowCropModal(false);
      setShowMenu(false);

      onImageChange(croppedFile);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  useEffect(() => {
    setPreviewImage(currentImageUrl || undefined);
  }, [currentImageUrl]);

  return (
    <div className="crate-image-cropper">
      {/* Display Image */}
      {previewImage ? (
        <IonImg alt="Crate Cover" src={previewImage} />
      ) : (
        editable && (
          <IonText style={{ zIndex: '2', position: 'relative' }}>
            Add An Image
          </IonText>
        )
      )}

      {/* Pencil Icon (Opens Menu) */}
      {editable && (
        <>
          <IonButton
            fill="clear"
            id="edit-image"
            onClick={() => setShowMenu(true)}
          >
            <IonIcon icon={pencil} />
          </IonButton>

          <IonPopover
            isOpen={showMenu}
            onDidDismiss={() => setShowMenu(false)}
            alignment="start"
            side="right"
            trigger="edit-image"
            className="crate-image-popover"
          >
            <IonList lines="none">
              <IonItem button onClick={() => fileInputRef.current?.click()}>
                Change Image
              </IonItem>
              <IonItem button onClick={() => setShowCropModal(true)}>
                Crop Image
              </IonItem>
            </IonList>
          </IonPopover>

          {/* Hidden File Input */}
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageSelect}
          />
        </>
      )}

      <IonModal isOpen={showCropModal}>
        <div style={{ width: '100%', height: '400px', position: 'relative' }}>
          {originalImage ? (
            <>
              <Cropper
                image={originalImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropComplete={handleCropComplete}
                onCropChange={setCrop}
                onZoomChange={setZoom}
              />
            </>
          ) : (
            <IonText>Please select an image first</IonText>
          )}
        </div>
        <IonButton color="success" fill="outline" onClick={handleCropSave}>
          Save
        </IonButton>

        <IonButton
          color="danger"
          fill="outline"
          onClick={() => setShowCropModal(false)}
        >
          Cancel
        </IonButton>
      </IonModal>
    </div>
  );
};

export default ImageCropper;
