import { IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { reorderThreeOutline, trashBinOutline } from 'ionicons/icons';
import React from 'react';
import { iTrack } from '../../types/ITrack';
import Waveform from '../waveform/Waveform';
import './Tracks.scss';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSelector } from 'react-redux';
import { selectCrateState } from '../../store/slices/crate.slice';
import { EditableBPMField } from './EditableBPMField';
import { EditableKeyField } from './EditableKeyField';
import { EditableNumberField } from './EditableNumberField';
import { EditableTextField } from './EditableTextField';
import './Track.scss';

export const TrackDetailsRow: React.FC<
  iTrack & {
    trackNum?: number;
    dragListeners?: SyntheticListenerMap;
    disabled?: boolean;
    onDeleteTrack?: (
      event: React.MouseEvent<HTMLIonIconElement, MouseEvent>,
    ) => void;
  }
> = ({
  ID,
  Artist,
  Title,
  AlbumCover,
  BPM,
  energy,
  danceability,
  Key_Camelot,
  valence,
  PreviewURL,
  trackNum,
  dragListeners,
  onDeleteTrack,
  disabled,
}) => {
  const { isEditable } = useSelector(selectCrateState);
  return (
    <IonRow className="track-details">
      <IonCol size="12" sizeMd="6" sizeLg="5">
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="track-number-container">
              <div {...dragListeners}>
                {isEditable && !disabled && (
                  <IonIcon
                    id="drag-handle"
                    color="primary"
                    icon={reorderThreeOutline}
                  />
                )}
              </div>
              <div className="track-circle">
                <div
                  className="track-number"
                  style={{ '--track-num': '"' + trackNum + '"' } as any}
                  color="dark"
                ></div>
              </div>
              {isEditable && (
                <div className="track-delete">
                  <IonIcon
                    className="track-delete-icon"
                    color="primary"
                    icon={trashBinOutline}
                    onClick={onDeleteTrack}
                  />
                </div>
              )}
            </IonCol>
            {AlbumCover && !disabled && (
              <IonCol size="auto">
                <img
                  className="album-cover"
                  src={AlbumCover}
                  alt={'album cover for track'}
                />
              </IonCol>
            )}
            <IonCol>
              <IonRow className="song-details-container">
                <EditableTextField
                  trackId={ID}
                  text={Title}
                  field="Title"
                  textColor="dark"
                />
              </IonRow>
              <IonRow className="song-details-container">
                <EditableTextField
                  trackId={ID}
                  text={Artist}
                  field="Artist"
                  textColor="medium"
                />
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCol>

      <IonCol size="12" sizeMd="6" sizeLg="5">
        <IonRow className="main-audio-feature-row">
          <IonCol>
            <IonText
              className="track-data-spec-key audio-feature-container"
              color="primary"
            >
              Key
            </IonText>
            <EditableKeyField
              className="audio-feature-container"
              trackId={ID}
              keyVal={Key_Camelot}
            />
          </IonCol>
          <IonCol>
            <IonText
              className="track-data-spec-key audio-feature-container"
              color="primary"
            >
              BPM
            </IonText>
            <EditableBPMField
              trackId={ID}
              bpmVal={BPM}
              className="audio-feature-container"
            />
          </IonCol>
          <IonCol>
            <IonText
              className="track-data-spec-key audio-feature-container"
              color="primary"
            >
              Energy
            </IonText>
            <EditableNumberField
              trackId={ID}
              field="energy"
              value={energy}
              className="audio-feature-container"
            />
          </IonCol>
          <IonCol>
            <IonText
              className="track-data-spec-key audio-feature-container"
              color="primary"
            >
              Dance
            </IonText>
            <EditableNumberField
              trackId={ID}
              field="danceability"
              value={danceability}
              className="audio-feature-container"
            />
          </IonCol>
          <IonCol>
            <IonText
              className="track-data-spec-key audio-feature-container"
              color="primary"
            >
              Mood
            </IonText>
            <EditableNumberField
              trackId={ID}
              field="valence"
              value={valence}
              className="audio-feature-container"
            />
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol size="12" sizeLg="2">
        {PreviewURL ? (
          <div className="center-content preview-container">
            <IonCol className="waveform-preview">
              <Waveform height={35} url={PreviewURL} />
            </IonCol>
          </div>
        ) : (
          <div className="no-preview-text">
            <IonText color="medium">No Preview Available</IonText>
          </div>
        )}
      </IonCol>
    </IonRow>
  );
};
