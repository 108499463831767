import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useState } from 'react';
import ProgressIndicator from '../../components/progress-indicator/progress-indicator';
import { once, run } from '../../helper/electron';
import { useIcons } from '../../hooks/useIcons';
import Flatten from './flatten';
import style from './folder-flattener.module.scss';
import Folders, { FolderData } from './folders';
import Verify from './verify';

export type FlattenedFolders = { current_path: string; new_path: string };
export default function FolderFlattener() {
  const { icons } = useIcons();

  const steps = [
    {
      label: 'Select Folders',
      callback: () => {
        console.log('Select Folders clicked');
        setCurrentStep(1);
      },
    },
    {
      label: 'Backup Library',
      callback: () => {
        console.log('Backup Library clicked');
        setCurrentStep(2);
        setShowBackupModal(true);
      },
    },
    {
      label: 'Set Destination',
      callback: () => {
        console.log('Set Destination clicked');
        setCurrentStep(3);
        setShowBackupModal(false);
      },
    },
    {
      label: 'Verify',
      callback: () => {
        console.log('Verify clicked');
        setCurrentStep(4);
      },
    },
    {
      label: 'Flatten',
      callback: () => {
        console.log('Flatten clicked');
        setCurrentStep(5);
      },
    },
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFolders, setSelectedFolders] = useState<FolderData[]>([]);
  const [destination, setDestination] = useState<FolderData>();
  const [showBackupModal, setShowBackupModal] = useState<boolean>(false);
  const [isReloadingFolders, setIsReloadingFolders] = useState<boolean>(false);
  const [isMovingFiles, setIsMovingFiles] = useState<boolean>(false);
  const [reloadFolderProgress, setReloadFolderProgress] = useState(0);
  const [flattenMap, setFlattenMap] = useState<FlattenedFolders[]>([]);
  const [fileOperationResponse, setFileOperationResponse] = useState<any>(null);

  const handleSelectFolder = async (data: FolderData[]) => {
    setSelectedFolders(data);
    setCurrentStep(2);
    setShowBackupModal(true);
  };

  const handleBackupLibrary = async () => {
    setCurrentStep(3);
    setShowBackupModal(false);
  };

  const onCloseBackupModal = async () => {
    setShowBackupModal(false);
  };

  const setDestinationButton = async () => {
    const openDialog = (response: FolderData[]) => {
      console.log('handleResponse:', response);
      setDestination(response[0]);
      setCurrentStep(4);
    };

    window.api?.once('open-directory-dialog', openDialog);
    run('open-directory-dialog', {
      options: ['openDirectory', 'createDirectory', 'promptToCreate'],
    });
  };

  const reloadFolders = async () => {
    if (isReloadingFolders) {
      return;
    }

    setIsReloadingFolders(true);
    console.log('reloadFolders');
    run('read-directory', {
      folderPaths: selectedFolders.map((folder) => folder.path),
    });
    once('read-directory', (folders: any) => {
      console.log('[ON] read-directory', folders);
      setSelectedFolders(folders);
      setIsReloadingFolders(false);
    });
  };

  const handleVerify = async (data: FlattenedFolders[]) => {
    setFlattenMap(data);
    if (isReloadingFolders) {
      return;
    }

    setIsMovingFiles(true);
    // TODO: Should be an option to move or copy
    run('file-operation', {
      action: 'copy',
      destination: destination?.path,
      items: data,
    });
    once('file-operation', (response: any) => {
      console.log('[ON] file-operation', response);
      setFileOperationResponse(response); // Store the response
      setIsMovingFiles(false);
      setCurrentStep(5);
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="4" sizeSm="12" sizeLg="3">
              <div className={style['page-header']}>
                <h1 className={style['title']}>
                  <IonIcon
                    icon={icons.folderFlattener}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  Folder Flattener
                </h1>
              </div>
            </IonCol>
            <IonCol sizeXs="8" sizeSm="12" sizeLg="7">
              <div className={style['step-header']}>
                <ProgressIndicator steps={steps} currentStep={currentStep} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol style={{ padding: '0 10px' }}>
              <div className={`${style['flatten-path']}`}>
                <>
                  {(currentStep == 1 || currentStep == 2) && (
                    // TODO: Send current folder data so form persists
                    <Folders callback={handleSelectFolder} />
                  )}
                  {currentStep == 2 && (
                    <IonAlert
                      isOpen={showBackupModal}
                      onDidDismiss={onCloseBackupModal}
                      cssClass={`backup-confirmation`}
                      header={'Did you back up your folders?'}
                      message={
                        'Please back up these files and folders before flattening.'
                      }
                      buttons={[
                        {
                          text: 'Go Back',
                          role: 'cancel',
                          cssClass: `modal backup-modal ${style['backup-modal']}`,
                          handler: () => {
                            onCloseBackupModal();
                          },
                        },
                        {
                          text: 'Continue',
                          handler: () => {
                            handleBackupLibrary();
                          },
                        },
                      ]}
                    />
                  )}
                  {currentStep == 3 && (
                    <>
                      <div className={style['select-folder']}>
                        <div className={'page-header'}>
                          <h1>Set Flattened Folder</h1>
                        </div>
                        <div>
                          <h3 style={{ textAlign: 'center' }}>
                            Pick a destination/folder for these files
                          </h3>
                          <button
                            className={
                              'primary ' + style['select-folder-button']
                            }
                            onClick={setDestinationButton}
                          >
                            <IonIcon
                              className="ion-icon"
                              icon={icons.folders}
                            />{' '}
                            Select Folder
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {currentStep == 4 && destination && (
                    <Verify
                      folders={selectedFolders}
                      destination={destination}
                      reloadFolders={reloadFolders}
                      isReloadingFolders={isReloadingFolders}
                      callback={handleVerify}
                    />
                  )}
                  {currentStep == 5 && fileOperationResponse && (
                    <Flatten response={fileOperationResponse} />
                  )}
                </>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
