/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddUserToGroupDto interface.
 */
export function instanceOfAddUserToGroupDto(value) {
    if (!('targetUserId' in value) || value['targetUserId'] === undefined)
        return false;
    if (!('permissionId' in value) || value['permissionId'] === undefined)
        return false;
    return true;
}
export function AddUserToGroupDtoFromJSON(json) {
    return AddUserToGroupDtoFromJSONTyped(json, false);
}
export function AddUserToGroupDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'targetUserId': json['targetUserId'],
        'permissionId': json['permissionId'],
    };
}
export function AddUserToGroupDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'targetUserId': value['targetUserId'],
        'permissionId': value['permissionId'],
    };
}
