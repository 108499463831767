/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginationMetaDtoFromJSON, PaginationMetaDtoToJSON, } from './PaginationMetaDto';
/**
 * Check if a given object implements the ReturnPaginatedDataDto interface.
 */
export function instanceOfReturnPaginatedDataDto(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    if (!('meta' in value) || value['meta'] === undefined)
        return false;
    return true;
}
export function ReturnPaginatedDataDtoFromJSON(json) {
    return ReturnPaginatedDataDtoFromJSONTyped(json, false);
}
export function ReturnPaginatedDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': json['data'],
        'meta': PaginationMetaDtoFromJSON(json['meta']),
    };
}
export function ReturnPaginatedDataDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': value['data'],
        'meta': PaginationMetaDtoToJSON(value['meta']),
    };
}
