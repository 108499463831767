import {
  IonButton,
  IonCol,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useRef } from 'react';
import './style.scss';

export const CrateFilterReorderDialog = ({
  isOpen,
  onDismiss,
  numHiddenTracks,
  onConfirm,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  numHiddenTracks: number;
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const isOneTrack = numHiddenTracks === 1;
  return (
    <>
      <IonModal
        className="reorder-filter-warning-dialog"
        ref={modal}
        isOpen={isOpen}
      >
        <IonToolbar>
          <IonHeader>
            <IonTitle>
              {numHiddenTracks} Track{isOneTrack ? '' : 's'} Currently Hidden
            </IonTitle>
          </IonHeader>
        </IonToolbar>
        <div className="reorder-filter-warning-dialog-content">
          There {isOneTrack ? 'is' : 'are'} {numHiddenTracks} track
          {isOneTrack ? '' : 's'} hidden from this crate due to an active
          filter. Re-ordering tracks while tracks are hidden{' '}
          <strong>
            will result in them being placed at the end of the crate's track
            list.
          </strong>
          <br />
          <br />
          Do you wish to continue?
        </div>
        <IonRow className="reorder-filter-warning-dialog-buttons">
          <IonCol size="auto">
            <IonButton
              color="light"
              onClick={() => {
                onDismiss();
                modal.current?.dismiss();
              }}
            >
              Cancel
            </IonButton>
          </IonCol>
          <IonCol size="auto" offset={'1'}>
            <IonButton color="primary" onClick={onConfirm}>
              Continue
            </IonButton>
          </IonCol>
        </IonRow>
      </IonModal>
    </>
  );
};
