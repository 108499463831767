import { CrateCategoryDto } from '@cratehackers/api-client';
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSpinner,
  IonText,
} from '@ionic/react';
import {
  addOutline,
  bugOutline,
  bulbOutline,
  documentTextOutline,
  globeOutline,
  help,
  laptopOutline,
} from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';
import { useIcons } from '../../hooks/useIcons';
import apiService from '../../services/Api.service';
import isElectron from '../../utils/isElectron';
import { openExternalLink } from '../../utils/openExternalLink';
import './Menu.scss';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [categories, setCategories] = useState<CrateCategoryDto[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categoryLoadError, setCategoryLoadError] = useState(false);
  const location = useLocation();
  const { icons } = useIcons();

  const normalizePath = (path: string) => {
    return path.startsWith('/') ? path.substring(1) : path;
  };

  const isNavActive = (url: string) => {
    const locationPath = normalizePath(location.pathname);
    const normalizedUrl = normalizePath(url);

    if (normalizedUrl.length === 0 && locationPath.length > 0) {
      return false;
    }

    return locationPath.startsWith(normalizedUrl);
  };

  const [version, setVersion] = useState('');

  useEffect(() => {
    setLoadingCategories(true);
    const chApi = apiService.getApiClient();
    chApi
      .crateControllerGetCrateCategories()
      .then((categories) => {
        setCategories(categories);
        setLoadingCategories(false);
        setCategoryLoadError(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCategories(false);
        setCategoryLoadError(true);
      });
  }, []);

  useEffect(() => {
    setVersion(config.VERSION ? config.VERSION : 'unknown');
  }, []);

  const navItems = useMemo(
    () => ({
      mainNavItems: [
        {
          id: 'home',
          title: 'Home',
          url: '/',
          src: icons.home,
          external: false,
        },
        {
          id: 'my-crates',
          title: 'My Crates',
          url: '/my-crates',
          src: icons.myCrates,
          external: false,
        },
        {
          id: 'my-music',
          title: 'My Music',
          url: '/my-music',
          src: icons.myMusic,
          external: false,
        },
        {
          id: 'trending',
          title: 'Trending',
          url: '/charts',
          src: icons.charts,
          external: false,
        },
        {
          id: 'mixable-crates',
          title: 'Mixable Crates',
          url: '/crates',
          src: icons.allCrates,
          external: false,
        },
        // {
        //   id: 'live-events',
        //   title: 'Live Events',
        //   url: '',
        //   src: '',
        //   external: false,
        // },
        // {
        //   id: 'tutorials',
        //   title: 'Tutorials',
        //   url: '/tutorials',
        //   src: hacks,
        //   external: false,
        // },
        // {
        //   id: 'marketplace',
        //   title: 'Marketplace',
        //   url: 'https://shop.cratehackers.com/',
        //   src: marketplace,
        //   external: true,
        // },
      ],
      tools: [
        {
          id: 'spotify-import',
          title: 'Spotify Import',
          url: '/my-crates/spotify',
          src: icons.spotify,
          external: false,
        },

        {
          id: 'text-import',
          title: 'Text Import',
          url: '/my-crates/text',
          src: icons.textImport,
          external: false,
        },
        {
          id: 'file-import',
          title: 'File Import',
          url: '/my-crates/file',
          src: documentTextOutline,
          external: false,
        },
        {
          id: 'djep-import',
          title: 'DJEP Import',
          url: '/my-crates/djep',
          src: icons.djepLogo,
          external: false,
        },
        {
          id: 'smpl-import',
          title: 'SMPL Import',
          url: '/my-crates/smpl',
          src: icons.smplLogo,
          external: false,
        },
        // {
        //   id: 'shazam-to-crate',
        //   title: 'Shazam To crate',
        //   url: '/tools/shazam-to-crate',
        //   src: shazamToCrate,
        //   external: false
        // },
        {
          id: 'folder-flattener',
          title: 'Folder Flattener',
          url: '/tools/folder-flattener',
          src: icons.folderFlattener,
          external: false,
        },
        // {
        //   id: 'bitrate-finder',
        //   title: 'Bitrate Finder',
        //   url: '/tools/bitrate-finder',
        //   src: '',
        //   external: false,
        // },
        // {
        //   id: 'stems-matcher',
        //   title: 'Stems Matcher',
        //   url: '/tools/stems-matcher',
        //   src: '',
        //   external: false,
        // },
      ],
      externalTools: [
        {
          id: 'web',
          title: 'Website',
          url: 'https://x.cratehackers.com',
          src: globeOutline,
          external: true,
        },
        {
          id: 'app',
          title: 'Desktop App',
          url: 'https://app.cratehackers.com/x',
          src: laptopOutline,
          external: true,
        },
        {
          id: 'banger-button',
          title: 'Banger Button',
          url: 'https://bangerbutton.com/',
          src: icons.bangerButtonLogo,
          external: true,
        },
        {
          id: 'magic-playlist',
          title: 'Magic Playlist',
          url: 'https://magicplaylist.co',
          src: icons.magicPlaylistLogo,
          external: true,
        },
      ],
      links: [
        {
          id: 'terms-of-service',
          title: 'Terms of Service',
          url: 'https://www.iubenda.com/terms-and-conditions/74902040',
          src: documentTextOutline,
          external: true,
        },
        {
          id: 'privacy-policy',
          title: 'Privacy Policy',
          url: 'https://www.iubenda.com/privacy-policy/74902040',
          src: documentTextOutline,
          external: true,
        },
        {
          id: 'cookie-policy',
          title: 'Cookie Policy',
          url: 'https://www.iubenda.com/privacy-policy/74902040/cookie-policy',
          src: documentTextOutline,
          external: true,
        },
        {
          id: 'acceptable-use-policy',
          title: 'Acceptable Use Policy',
          url: 'https://app.cratehackers.com/acceptableuse',
          src: documentTextOutline,
          external: true,
        },
        {
          id: 'help',
          title: 'Help',
          url: 'https://help.cratehackers.com',
          src: help,
          external: true,
        },
        {
          id: 'bug',
          title: 'Report Bug',
          url: '/feedback/bug',
          src: bugOutline,
          external: false,
        },
        {
          id: 'bug',
          title: 'Feature Request',
          url: '/feedback/feature-request',
          src: bulbOutline,
          external: false,
        },
      ],
      externalPartners: [
        {
          id: 'future-dj',
          title: 'The Future DJ',
          url: 'https://www.thefuturedj.com/',
          src: icons.futureDJLogo,
          external: true,
        },
        {
          id: 'dangerous-djs',
          title: 'Dangerous DJs',
          url: 'https://www.dangerousdjs.com/',
          src: icons.dangerousDJsLogo,
          external: true,
        },
      ],
    }),
    [icons],
  );

  return (
    <IonMenu
      contentId="main"
      type="overlay"
      onIonWillOpen={() => setMenuOpen(true)}
      onIonDidClose={() => setMenuOpen(false)}
      className={menuOpen ? 'menu-pane-visible' : ''}
    >
      <IonContent>
        <IonList id="mainNavItems-list menu-list">
          {navItems.mainNavItems.map((navItem, index) => {
            if (navItem.id === 'my-music' && !isElectron()) {
              return null;
            }
            const { id, url, src, title, external } = navItem;
            return (
              <IonMenuToggle key={index} autoHide={false}>
                {external ? (
                  <IonItem
                    id={id}
                    className={`${isNavActive(url) ? 'active' : ''} ${id} external-link`}
                    lines="none"
                    detail={false}
                    onClick={() => openExternalLink(url)}
                  >
                    <IonIcon aria-hidden="true" slot="start" src={src} />
                    <IonLabel>{title}</IonLabel>
                  </IonItem>
                ) : (
                  <IonItem
                    id={id}
                    className={`${isNavActive(url) ? 'active' : ''} ${id}`}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                    routerLink={url}
                  >
                    <IonIcon aria-hidden="true" slot="start" src={src} />
                    <IonLabel>{title}</IonLabel>
                    {id === 'my-crates' && (
                      <IonButton
                        routerLink="/my-crates/home"
                        // fill="outline"
                        shape="round"
                        color="light"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <IonIcon className="ion-icon" icon={addOutline} />
                      </IonButton>
                    )}
                  </IonItem>
                )}
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonAccordionGroup multiple={true}>
          <IonAccordion value="categories">
            <IonItem slot="header" color="light">
              <IonLabel>Crate Categories</IonLabel>
            </IonItem>
            <IonList slot="content" id="menu-list">
              {categoryLoadError && (
                <IonText color="medium">Error loading categories</IonText>
              )}
              {loadingCategories && !categoryLoadError && (
                <IonItem lines="none">
                  <IonLabel>Loading categories...</IonLabel>
                  <IonSpinner name="dots" />
                </IonItem>
              )}
              {!loadingCategories &&
                !categoryLoadError &&
                categories &&
                categories.length > 0 &&
                categories.map((category, index) => (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      lines="none"
                      className={`${isNavActive('/category/' + category.id) ? 'active' : ''}`}
                      id={category.id}
                      routerLink={'/category/' + category.id}
                    >
                      <IonLabel>{category.category}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                ))}
            </IonList>
          </IonAccordion>
          <IonAccordion value="tools">
            <IonItem slot="header" color="light">
              <IonLabel>Tools</IonLabel>
            </IonItem>
            <IonList slot="content" id="tools-list menu-list">
              {navItems.tools.map((navItem, index) => {
                const { id, url, src, title, external } = navItem;
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    {external ? (
                      <IonItem
                        id={id}
                        className={`${isNavActive(url) ? 'active' : ''} ${id} external-link`}
                        lines="none"
                        detail={false}
                        onClick={() => openExternalLink(url)}
                      >
                        <IonIcon aria-hidden="true" slot="start" src={src} />
                        <IonLabel>{title}</IonLabel>
                      </IonItem>
                    ) : (
                      <IonItem
                        id={id}
                        className={`${isNavActive(url) ? 'active' : ''} ${id}`}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                        routerLink={url}
                      >
                        <IonIcon aria-hidden="true" slot="start" src={src} />
                        <IonLabel>{title}</IonLabel>
                      </IonItem>
                    )}
                  </IonMenuToggle>
                );
              })}
            </IonList>
          </IonAccordion>

          <IonAccordion value="externalTools">
            <IonItem slot="header" color="light">
              <IonLabel>External Tools</IonLabel>
            </IonItem>
            <IonList slot="content" id="externalTools-list menu-list">
              {navItems.externalTools.map((navItem, index) => {
                const { id, url, src, title, external } = navItem;
                if (
                  (id === 'web' && !isElectron()) ||
                  (id === 'app' && isElectron())
                ) {
                  return null; // Skip rendering
                }
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    {external ? (
                      <IonItem
                        id={id}
                        className={`${isNavActive(url) ? 'active' : ''} ${id} external-link`}
                        lines="none"
                        detail={false}
                        onClick={() => openExternalLink(url)}
                      >
                        <IonIcon aria-hidden="true" slot="start" src={src} />
                        <IonLabel>{title}</IonLabel>
                      </IonItem>
                    ) : (
                      <IonItem
                        id={id}
                        className={`${isNavActive(url) ? 'active' : ''} ${id}`}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                        routerLink={url}
                      >
                        <IonIcon aria-hidden="true" slot="start" src={src} />
                        <IonLabel>{title}</IonLabel>
                      </IonItem>
                    )}
                  </IonMenuToggle>
                );
              })}
            </IonList>
          </IonAccordion>

          <IonAccordion value="links">
            <IonItem slot="header" color="light">
              <IonLabel>Links</IonLabel>
            </IonItem>
            <IonList slot="content" id="links-list menu-list">
              {navItems.links.map((navItem, index) => {
                const { id, url, src, title, external } = navItem;
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    {external ? (
                      <IonItem
                        id={id}
                        className={`${isNavActive(url) ? 'active' : ''} ${id} external-link`}
                        lines="none"
                        detail={false}
                        onClick={() => openExternalLink(url)}
                      >
                        <IonIcon aria-hidden="true" slot="start" src={src} />
                        <IonLabel>{title}</IonLabel>
                      </IonItem>
                    ) : (
                      <IonItem
                        id={id}
                        className={`${isNavActive(url) ? 'active' : ''} ${id}`}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                        routerLink={url}
                      >
                        <IonIcon aria-hidden="true" slot="start" src={src} />
                        <IonLabel>{title}</IonLabel>
                      </IonItem>
                    )}
                  </IonMenuToggle>
                );
              })}
            </IonList>
          </IonAccordion>
        </IonAccordionGroup>
      </IonContent>
      <IonFooter>
        <div className="footer-items">
          <IonList id="externalPartners-list menu-list">
            {navItems.externalPartners.map((navItem, index) => {
              const { id, url, src, title, external } = navItem;
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  {external ? (
                    <IonItem
                      id={id}
                      className={`${isNavActive(url) ? 'active' : ''} ${id} external-link`}
                      lines="none"
                      detail={false}
                      onClick={() => openExternalLink(url)}
                    >
                      <IonIcon aria-hidden="true" slot="start" src={src} />
                      <IonLabel>{title}</IonLabel>
                    </IonItem>
                  ) : (
                    <IonItem
                      id={id}
                      className={`${isNavActive(url) ? 'active' : ''} ${id}`}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                      routerLink={url}
                    >
                      <IonIcon aria-hidden="true" slot="start" src={src} />
                      <IonLabel>{title}</IonLabel>
                    </IonItem>
                  )}
                </IonMenuToggle>
              );
            })}
          </IonList>
          <IonText className="ion-padding" color="dark">
            <div className="">
              <span>Version {version}</span>
            </div>
          </IonText>
          <IonText className="ion-padding" color="dark">
            <div className="">
              <span>
                Copyright Crate Hackers &copy; {new Date().getFullYear()}
              </span>
            </div>
          </IonText>
        </div>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
