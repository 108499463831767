import { IonIcon, IonPopover, IonSpinner } from '@ionic/react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  ellipsisVertical,
  folderOpenOutline,
  trashOutline,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import '../../components/aggrid/menu.scss';
import ProgressButton from '../../components/buttons/ProgressButton';
import { on, once, removeListener, run } from '../../helper/electron';
import { useIcons } from '../../hooks/useIcons';
import { RootState } from '../../store/store';
import './Folders.scss';

const Folders: React.FC = () => {
  const { icons } = useIcons();

  type FolderRecord = {
    id: string;
    path: string;
    drive_id: string; // TODO: Reference a drive object, this way we can simply ask if it's mounted and show something in UI.
  };

  const history = useHistory();
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [gridApi, setGridApi] = useState<any>();
  const [folders, setFolders] = useState<FolderRecord[]>([]);
  const [rowData, setRowData] = useState<FolderRecord[]>([]);
  const [folderProgressMap, setFolderProgressMap] = useState<
    Record<string, number>
  >({});
  const [isAddingFolders, setIsAddingFolders] = useState<boolean>(false);
  const [addFolderProgress, setAddFolderProgress] = useState(0);
  const [isRescanningFolders, setIsRescanningFolders] =
    useState<boolean>(false);
  const [loadingStates, setLoadingStates] = useState<Map<number, boolean>>(
    new Map(),
  );

  const updateLoadingState = (index: number, isLoading: boolean) => {
    setLoadingStates((prev: Map<number, boolean>) => {
      const newState = new Map(prev);
      newState.set(index, isLoading);
      return newState;
    });
  };

  const ActionCellRenderer: React.FC<any> = ({
    data,
    loadingStates,
    updateLoadingState,
  }) => {
    const [popoverState, setShowPopover] = useState({
      showPopover: false,
      event: undefined,
    });

    const handleItemClick = (itemIndex: number, item: any) => {
      // Set the loading state for the clicked item
      updateLoadingState(itemIndex, true);

      // Call the action and reset the loading state after completion
      item.action(data, () => {
        setShowPopover({ showPopover: false, event: undefined });
        updateLoadingState(itemIndex, false);
      });
    };

    return (
      <div className="ion-icon-container">
        <IonIcon
          className="ion-icon"
          icon={ellipsisVertical}
          onClick={(e: any) => {
            e.persist();
            setShowPopover({ showPopover: true, event: e });
          }}
        />
        <IonPopover
          className="add-folder-popover"
          isOpen={popoverState.showPopover}
          event={popoverState.event}
          onDidDismiss={() =>
            setShowPopover({ showPopover: false, event: undefined })
          }
        >
          <div className="context-menu">
            <ul>
              {menuItems.map((item, index) => (
                <li key={index} onClick={() => handleItemClick(index, item)}>
                  {loadingStates.get(index) ? (
                    <IonSpinner name="dots" />
                  ) : (
                    <>
                      {item.icon && (
                        <IonIcon className="ion-icon" icon={item.icon} />
                      )}
                    </>
                  )}
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        </IonPopover>
      </div>
    );
  };

  const [columnDefs] = useState([
    { field: 'id', hide: true },
    {
      field: 'path',
      headerName: 'Folder Path',
      flex: 1,
      draggable: false,
      editable: false,
      filter: false,
      lockPosition: true,
      resizable: false,
      sortable: true,
      suppressMenu: true,
    },
    {
      field: 'trackCount',
      headerName: 'Total Tracks',
      flex: 0.5,
      draggable: false,
      editable: false,
      filter: false,
      lockPosition: true,
      resizable: false,
      sortable: true,
      suppressMenu: true,
    },
    {
      field: 'action',
      headerName: '',
      cellRenderer: (params: any) => (
        <ActionCellRenderer
          {...params}
          loadingStates={loadingStates}
          updateLoadingState={updateLoadingState}
        />
      ),
      width: 100,
      draggable: false,
      editable: false,
      filter: false,
      lockPosition: true,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
  ]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  // Page Button Actions
  const addFolder = async () => {
    // Check if another prompt is already open
    if (isAddingFolders) {
      return;
    }

    setIsAddingFolders(true);
    run('my-music/add-folder');
  };

  const rescanFolders = async () => {
    // Check if another prompt is already open
    if (isRescanningFolders) {
      return;
    }

    setIsRescanningFolders(true);
    run('my-music/rescan-folders');
  };

  // Row Actions
  const openInExplorer = async (data: any) => {
    console.log('open-file-in-explorer arg:', data);
    run('open-file-in-explorer', { filePath: data.path });
  };

  const deleteFolder = async (data: any) => {
    console.log('my-music/delete-folder arg:', { folder: data });
    run('my-music/delete-folder', { folder: data });
  };

  const rescanFolder = async (data: any) => {
    // Check if another prompt is already open
    if (isRescanningFolders) {
      return;
    }

    console.log('my-music/rescan-folder arg:', { folder: data });

    // Update the "Total Tracks" column to "Scanning folder..."
    setRowData((prevRowData) =>
      prevRowData.map((row) =>
        row.id === data.id
          ? { ...row, trackCount: 'Scanning folder...' } // Update the trackCount for the specific folder
          : row,
      ),
    );

    // Send IPC request to re-scan folder
    run('my-music/rescan-folder', { folder: data });

    setIsRescanningFolders(true);
  };

  const menuItems = [
    {
      label: 'Open File Explorer',
      icon: folderOpenOutline,
      action: openInExplorer,
    },
    { label: 'Delete', icon: trashOutline, action: deleteFolder },
    {
      label: 'Re-Scan Folder(s)',
      icon: icons.rotateLeft,
      action: rescanFolder,
    },
  ];

  type ResponseGetMyMusicFolder = any & {
    data: FolderRecord[];
  };
  const getMyMusicFolders = (response: ResponseGetMyMusicFolder) => {
    console.log('[ON] my-music/get-folders:', response);

    // Initially set the row data in the grid
    setRowData(
      response.map((folder: any) => ({
        ...folder,
        trackCount: 'Loading...', // Initialize 'trackCount' column (or other relevant data)
      })),
    );

    setFolders(response);

    // Resize initial columns
    gridApi?.api?.autoSizeColumns(['path']);
  };

  useEffect(() => {
    // Request track data for each folder
    folders.forEach((folder: any) => {
      removeListener(`my-music/get-folder-tracks-${folder.id}`);

      // Trigger IPC call
      run('my-music/get-folder-tracks', { folder: folder });

      // Listen for the IPC response for this folder
      once(`my-music/get-folder-tracks-${folder.id}`, (trackData: any) => {
        console.log(`Track data for folder ${folder.id}:`, trackData);

        // Update the row's data with the track data
        setRowData((prevRowData) =>
          prevRowData.map((row) =>
            row.id === folder.id
              ? { ...row, trackCount: trackData.length } // Add or update the 'trackCount' column
              : row,
          ),
        );

        // Optionally resize columns to fit new data if needed
        gridApi?.api?.autoSizeColumns();
      });
    });
  }, [folders]);

  useEffect(() => {
    on('my-music/get-folders', getMyMusicFolders);
    run('my-music/get-folders');

    on('my-music/rescan-folders', (response: any) => {
      if (response === true) return;
      if (response.numOfProcessed === response.total) {
        // Update "Total Tracks" when the rescan is completed
        setRowData((prevRowData) =>
          prevRowData.map((row) =>
            row.id === response.folder.id
              ? {
                  ...row,
                  trackCount: response.total || 0, // Set to the actual total tracks once done
                }
              : row,
          ),
        );

        updateLoadingState(response.folder.index, false); // Update the loading state
        setIsRescanningFolders(false);
      } else {
        // While scanning is in progress, show "Scanning folder..."
        setRowData((prevRowData) =>
          prevRowData.map((row) =>
            row.id === response.folder.id
              ? {
                  ...row,
                  trackCount: 'Scanning folder...', // Set to "Scanning folder..." during progress
                }
              : row,
          ),
        );

        setIsRescanningFolders(true);
      }

      setFolderProgressMap((prev) => ({
        ...prev,
        [response.folder.id]: (response.numOfProcessed / response.total) * 100,
      }));
    });

    on('my-music/delete-folder', (response: any) => {
      console.log('[ON] my-music/delete-folder', response);
      run('my-music/get-folders');
    });

    on('my-music/add-folder', (response: any) => {
      if (response.total && response.numOfProcessed) {
        setAddFolderProgress((response.numOfProcessed / response.total) * 100);
        setFolderProgressMap((prev) => ({
          ...prev,
          [response.folder.id]:
            (response.numOfProcessed / response.total) * 100,
        }));
      } else {
        setIsAddingFolders(false);
        if (response?.folder?.id) {
          setFolderProgressMap((prev) => {
            const updated = { ...prev };
            delete updated[response.folder.id];
            return updated;
          });
        }
        run('my-music/get-folders');
      }
    });
  }, []);

  return (
    <>
      <div className="page-header">
        <h1>
          My Music <span>/ Folders</span>
        </h1>
        <div id="actions" className="button-group">
          <button className="primary btn-transparent" onClick={handleGoBack}>
            <IonIcon className="ion-icon" icon={icons.arrowLeft} /> Back
          </button>
          <ProgressButton
            id="add-folders-btn"
            className="primary"
            isLoading={isAddingFolders}
            progress={addFolderProgress}
            icon={icons.plus}
            onClick={addFolder}
          >
            Add Folder
          </ProgressButton>
          <ProgressButton
            id="rescan-folders-btn"
            className="primary"
            isLoading={isRescanningFolders}
            icon={icons.rotateLeft}
            onClick={rescanFolders}
          >
            Re-scan Folder(s)
          </ProgressButton>
        </div>
      </div>
      <div
        className={`ag-theme-alpine${
          theme === 'dark' ? '-dark' : ''
        } folder-grid`}
      >
        <AgGridReact
          className={`ag-theme-alpine${theme === 'dark' ? '-dark' : ''} grid`}
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          getRowStyle={(params) => {
            const progress = folderProgressMap[params.data.id];
            if (progress < 100 && progress > 0) {
              return {
                background: `linear-gradient(
                 to right,
                  rgb(18 69 2) 0%,
                rgb(18 69 2) ${progress}%,
                transparent ${progress}%,
                transparent
                )`,
              };
            }
            return undefined;
          }}
          rowSelection="single" // Options - allows click selection of rows
          onGridReady={onGridReady}
          rowHeight={60}
          suppressScrollOnNewData={true}
        />
      </div>
    </>
  );
};

export default Folders;
