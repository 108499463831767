import { History } from 'history';
import { camelCase } from 'lodash';
import { config } from '../config';
import { iCrate } from '../types/ICrate';
import ApiService from './Api.service';
import RequestService from './Request.service';

interface ApiResponse {
  count: number;
  cursor?: number; // the cursor can be optional if not always present
  remaining: number;
  results: any[]; // if you can type the results further, replace `any[]` with the more specific type
}

export class CrateService {
  static CRATE_STANDARD = 'standard';
  static CRATE_HACKATHON = 'hackathon';
  static CRATE_MARKETPLACE = 'marketplace';
  static MY_CRATES = 'user';

  private static crateEndpoints: { [key: string]: string } = {
    [CrateService.CRATE_STANDARD]: 'crates/standard',
    [CrateService.CRATE_HACKATHON]: 'crates/hackathon',
    [CrateService.CRATE_MARKETPLACE]: 'crates/marketplace',
    [CrateService.MY_CRATES]: 'crates/my-crates',
  };

  // Mapping for camelCase and original crate keys
  private static readonly iCrateKeys = [
    '_id',
    'Playlist ID',
    'Title',
    'Description',
    'Image',
    'Created Date',
    'Modified Date',
    'Published',
    'withTracks',
    'Tracks',
    'condensed',
    'Hacker',
    'Category',
    'StreamDeezer',
    'StreamAppleMusic',
    'StreamBeatsource',
    'Rekordcloud',
    'StreamSoundcloud',
    'StreamSpotify',
    'StreamTidal',
    'trackCount',
    'createdFrom',
    'Checked',
    'Facebook Live',
    'VDJ',
    'Bookmarks',
    'Status',
    'Type',
    'Live',
  ] as const;

  private static readonly iCrateMapping = CrateService.createMapping(
    CrateService.iCrateKeys,
  );

  private static createMapping(
    keys: readonly string[],
  ): Record<string, string> {
    const mapping: Record<string, string> = {};

    keys.forEach((key) => {
      const camelKey = camelCase(key); // Transform keys to camelCase
      mapping[camelKey] = key; // Map camelCase -> Original
    });

    return mapping;
  }

  // Helper to map from camelCase to original case
  public static mapToOriginalCase(
    currentCrate: Record<string, any>,
  ): Record<string, any> {
    const updatedCrate: Record<string, any> = {};

    for (const key in currentCrate) {
      const originalKey = CrateService.iCrateMapping[key] || key;
      updatedCrate[originalKey] = currentCrate[key];
    }

    return updatedCrate;
  }

  public static async fetchCrate(id: string, callback: (data: iCrate) => void) {
    const url = `${config.API_BASE_URL}/crates/${id}`;
    const data = await RequestService.fetch({ url });
    return callback(data);
  }

  public static async fetchCratesByType(
    crateType: string,
    callback: (data: any) => void,
    limit?: number,
    history?: History,
  ) {
    if (CrateService.crateEndpoints[crateType]) {
      const data = await CrateService.fetchCratesFromEndpoint(
        CrateService.crateEndpoints[crateType],
        limit,
        history,
      );
      return callback(data);
    }
    throw new Error(`Invalid crate type: ${crateType}`);
  }

  public static async fetchSelectedCrates(
    crateTypes: string[],
    callback: (data: any[]) => void,
    limit?: number,
    history?: History,
  ) {
    const fetchPromises = crateTypes.map((crateType) =>
      CrateService.fetchCratesByType(crateType, (d) => d, limit, history),
    );

    const results = await Promise.all(fetchPromises);
    const combinedResults = results.flat();
    callback(combinedResults);
  }

  public static async createMyCrate(crateData: iCrate) {
    // Destructure the _id property out and capture the rest of crateData
    const { _id, ...dataWithoutId } = crateData;
    const url = `${config.API_BASE_URL}/crates`;
    const method = 'POST';
    const body = dataWithoutId;
    return await RequestService.fetch({
      url,
      method,
      body,
    });
  }

  // Modified `updateMyCrate` method
  public static async updateMyCrate(
    crate: iCrate,
  ): Promise<iCrate | undefined> {
    try {
      const api = ApiService.getApiClient();

      const mapToLowerCamelCase = (currentCrate: any) => {
        const updatedCrate: any = {};
        for (const key in currentCrate) {
          const camelCaseKey = camelCase(key);
          updatedCrate[camelCaseKey] = currentCrate[key];
        }
        return updatedCrate;
      };

      const updatedCrate = mapToLowerCamelCase({ ...crate });

      // Example: convert published date if needed
      if (updatedCrate.published) {
        updatedCrate.published = new Date(updatedCrate.published);
      }

      // Perform the API update call
      const apiResponse = await api.crateControllerUpdateCrate({
        id: crate._id,
        updateCrateDto: updatedCrate,
      });

      console.log('Updated crate (API response):', apiResponse);

      // Transform the response back to the original keys and explicitly set its type
      const originalKeysCrate = CrateService.mapToOriginalCase(
        apiResponse,
      ) as iCrate;
      console.log('Transformed back to original keys:', originalKeysCrate);

      return originalKeysCrate; // Resolve with updated crate
    } catch (error) {
      console.error('Error updating crate:', error);
      throw error; // Reject promise on failure
    }
  }

  // TODO: Change to followed crates
  public static fetchFollowedCratePosts(
    callback: (data: any) => void,
    id?: string,
  ) {
    this.fetchSelectedCrates(
      [CrateService.CRATE_STANDARD, CrateService.CRATE_HACKATHON],
      (data) => {
        const cratePostsWithType = data.map((crate) => ({
          ...crate,
          postType: 'crate',
        }));
        callback(cratePostsWithType);
      },
      50,
    );
  }

  private static async fetchCratesFromEndpoint(
    endpoint: string,
    limit?: number,
    history?: History,
  ): Promise<any[]> {
    let results: any[] = [];
    let cursor = 0;
    let counter = 0;

    while (cursor !== undefined) {
      const url = `${config.API_BASE_URL}/${endpoint}`;
      const method = 'GET';
      const data: ApiResponse = await RequestService.fetch({
        url,
        method,
        cursor,
        limit,
      }).catch((error: any) => {
        console.log(error);
      });

      if (data.results) {
        results = results.concat(data.results);
        counter += data.results.length;
      }

      if (data.cursor) {
        cursor = cursor + data.count; // Update the cursor value based on the API response
      }

      if (limit && counter >= limit) {
        results = results.slice(0, limit);
        break;
      }

      if (!data.remaining || data.remaining === 0) {
        break;
      }
    }

    return results;
  }

  private static async fetchStandardCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_STANDARD],
    );
  }

  private static async fetchHackathonCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_HACKATHON],
    );
  }

  private static async fetchMarketplaceCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_MARKETPLACE],
    );
  }
}
