import { AppIdentifiers } from '../store/slices/token-state.slice';
import store from '../store/store';
import fetchWrapper from '../utils/fetchWrapper';

interface FetchParams {
  url: string;
  method?: string;
  body?: any;
  cursor?: number;
  limit?: number;
  token?: AppIdentifiers;
}

export default class RequestService {
  static async fetch({
    url,
    method = 'GET',
    body,
    cursor,
    limit,
    token = AppIdentifiers.App,
  }: FetchParams) {
    // Access the token for the specified type
    const appToken = store.getState().tokenState[token] ?? '';

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${appToken.accessToken}`,
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }
    if (cursor !== undefined) {
      url += (url.includes('?') ? '&' : '?') + `cursor=${cursor}`;
    }

    if (limit !== undefined) {
      url += (url.includes('?') ? '&' : '?') + `limit=${limit}`;
    }

    return await fetchWrapper(url, options);
  }
}
