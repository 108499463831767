import { useDispatch } from 'react-redux';
import { addToast as addToastAction } from '../../store/slices/toast.slice';
import { Toast } from './types';

/**
 * Used to display toast messages on screen for users
 */
export const useToast = () => {
  const dispatch = useDispatch();

  const addToast = (toast: Omit<Toast, 'id'>) =>
    dispatch(addToastAction(toast));

  return {
    addToast,
    // Additional variants can be added in the future, these are the two most common
    successToast: (toast: Omit<Toast, 'id' | 'variant'>) =>
      addToast({ ...toast, variant: 'success' }),
    errorToast: (toast: Omit<Toast, 'id' | 'variant'>) =>
      addToast({ ...toast, variant: 'danger' }),
  };
};
