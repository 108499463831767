import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRouterLink,
  IonRow,
} from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import {
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
  colorWandOutline,
  scan,
  search,
} from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import { useBreakpoint } from '../../context/breakpoint/BreakpointContext';
import { CrateService } from '../../services/Crate.service';
import isElectron from '../../utils/isElectron';
import { CrateGridMini } from '../crates/crateGridMini/CrateGridMini';
import './Feed.scss';
import Slide from './Slide';
import './Slide.scss';
import videos from './videos.json';

const Feed: React.FC<{ scrollPosition: number }> = ({ scrollPosition }) => {
  const [selectedSegment, setSelectedSegment] = useState<string>('feed');
  const history = useHistory();
  const sliderRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const { matchesMediumDown, matchesLargeDown } = useBreakpoint();

  return (
    <div className={'crate-grid-mini'}>
      {isElectron() && (
        <>
          <div className="header">
            <p>What do you want to do today?</p>
          </div>

          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="6" sizeLg="4">
                <IonRouterLink routerLink="/my-music">
                  <div className="card">
                    <div className="overlap-group">
                      <IonIcon icon={scan} size="large" color="primary" />
                      <div className="title">Scan</div>
                      <p className="text-wrapper">
                        To get the most out of Crate Hackers, you need to scan
                        your music library.
                      </p>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>

              <IonCol size="12" sizeMd="6" sizeLg="4">
                <IonRouterLink routerLink={'/crates'}>
                  <div className="card">
                    <div className="overlap-group">
                      <IonIcon icon={search} size="large" color="primary" />
                      <div className="title">Find</div>
                      <p className="text-wrapper">
                        Trending Crates, Charts, and Track Recommendations
                      </p>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>

              <IonCol size="12" sizeMd="12" sizeLg="4">
                <IonRouterLink routerLink={'/my-crates/home'}>
                  <div className="card">
                    <div className="overlap-group">
                      <IonIcon
                        icon={colorWandOutline}
                        size="large"
                        color="primary"
                      />
                      <div className="title">Build</div>
                      <p className="text-wrapper">
                        Build your own crate using our Recommendations
                      </p>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      )}

      <div className="header">
        <p>Get Started</p>
        <div className="swiper-container">
          <IonGrid>
            <IonRow>
              <Swiper
                ref={sliderRef}
                modules={[Navigation]}
                loop={true}
                slidesPerView={matchesMediumDown ? 1 : matchesLargeDown ? 2 : 3}
                spaceBetween={20}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper: SwiperType) => {
                  const navigation = swiper.params
                    .navigation as NavigationOptions;
                  navigation.prevEl = navigationPrevRef.current;
                  navigation.nextEl = navigationNextRef.current;
                }}
                className="swiper-with-buttons"
              >
                {videos.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Slide
                      link={item.link}
                      videoId={item.videoId}
                      imgAlt={item.imgAlt}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </IonRow>
          </IonGrid>
          <div className="swiper-buttons">
            <IonButton
              shape="round"
              color="light"
              ref={navigationPrevRef}
              className="swiper-button-prev"
            >
              <IonIcon
                slot="icon-only"
                size="large"
                icon={arrowBackCircleOutline}
              />
            </IonButton>
            <IonButton
              shape="round"
              color="light"
              ref={navigationNextRef}
              className="swiper-button-next"
            >
              <IonIcon
                slot="icon-only"
                size="large"
                icon={arrowForwardCircleOutline}
              />
            </IonButton>
          </div>
        </div>
      </div>

      <div className="header">
        <p>Latest Crates</p>
      </div>
      <CrateGridMini categories={[CrateService.CRATE_STANDARD]} limit={6} />
      <div className="header">
        <p>Latest Hackathons</p>
      </div>
      <CrateGridMini categories={[CrateService.CRATE_HACKATHON]} limit={6} />
    </div>
  );
};

export default Feed;
