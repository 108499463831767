import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsLoading,
  setIsLoading as setIsLoadingAction,
} from '../../store/slices/loading.slice';
import { AppDispatch } from '../../store/store';

/**
 * Manages global loading state in the app
 */
export const useLoading = () => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch<AppDispatch>();

  const setIsLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setIsLoadingAction(isLoading));
    },
    [dispatch],
  );
  return {
    isLoading,
    setIsLoading,
  };
};
