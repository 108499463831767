import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRouterLink,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useIcons } from '../../../hooks/useIcons';
import { iCrate } from '../../../types/ICrate';
import { openExternalLink } from '../../../utils/openExternalLink';

interface CloudCrateProps {
  editable: boolean;
  crateData: iCrate;
  onUpdateStreamingLinks: (updatedLinks: Partial<iCrate>) => void;
}

export const CloudCrates: React.FC<CloudCrateProps> = ({
  editable,
  crateData,
  onUpdateStreamingLinks,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { icons } = useIcons();
  const [streamingLinks, setStreamingLinks] = useState<Partial<iCrate>>({
    StreamSpotify: crateData.StreamSpotify || '',
    StreamAppleMusic: crateData.StreamAppleMusic || '',
    StreamSoundcloud: crateData.StreamSoundcloud || '',
    StreamBeatsource: crateData.StreamBeatsource || '',
    StreamDeezer: crateData.StreamDeezer || '',
    StreamTidal: crateData.StreamTidal || '',
  });

  // Reset streaming links when crateData changes
  useEffect(() => {
    setStreamingLinks({
      StreamSpotify: crateData.StreamSpotify || '',
      StreamAppleMusic: crateData.StreamAppleMusic || '',
      StreamSoundcloud: crateData.StreamSoundcloud || '',
      StreamBeatsource: crateData.StreamBeatsource || '',
      StreamDeezer: crateData.StreamDeezer || '',
      StreamTidal: crateData.StreamTidal || '',
    });
  }, [crateData]);

  /** Handle updating the streaming links */
  const handleInputChange = (platform: keyof iCrate, value: string) => {
    setStreamingLinks((prev) => ({
      ...prev,
      [platform]: value,
    }));
  };

  /** Handle saving the updated links */
  const handleSaveLinks = () => {
    onUpdateStreamingLinks(streamingLinks);
    setIsModalOpen(false);
  };

  return (
    <>
      <IonGrid className="cloud-crate-grid">
        <IonRow>
          {/* Edit Button (Only If Editable) */}
          {editable && (
            <IonCol className="ion-text-end">
              <IonButton fill="clear" onClick={() => setIsModalOpen(true)}>
                Edit Cloud Crates
              </IonButton>
            </IonCol>
          )}
        </IonRow>
        <IonRow className="ion-align-items-center">
          {/* Display Cloud Crates Icon if Any Links Exist */}
          {(crateData?.StreamDeezer ||
            crateData?.StreamTidal ||
            crateData?.StreamBeatsource ||
            crateData?.StreamSpotify ||
            crateData?.StreamSoundcloud ||
            crateData?.StreamAppleMusic) && (
            <IonCol className="ion-justify-content-center">
              <IonItem className="cloud-crates-icon">
                <IonIcon icon={icons.cloudCrates}></IonIcon>
              </IonItem>
            </IonCol>
          )}

          {crateData?.StreamSpotify && (
            <IonCol>
              <IonRouterLink
                className={'cloud-crate-item'}
                onClick={() => openExternalLink(crateData.StreamSpotify || '')}
                style={{ cursor: 'pointer' }}
              >
                <IonIcon icon={icons.spotifyFull}></IonIcon>
              </IonRouterLink>
            </IonCol>
          )}
          {crateData?.StreamAppleMusic && (
            <IonCol>
              <IonRouterLink
                className={'cloud-crate-item'}
                onClick={() =>
                  openExternalLink(crateData.StreamAppleMusic || '')
                }
                style={{ cursor: 'pointer' }}
              >
                <IonIcon icon={icons.apple}></IonIcon>
              </IonRouterLink>
            </IonCol>
          )}
          {crateData?.StreamSoundcloud && (
            <IonCol>
              <IonRouterLink
                className={'cloud-crate-item'}
                onClick={() =>
                  openExternalLink(crateData.StreamSoundcloud || '')
                }
                style={{ cursor: 'pointer' }}
              >
                <IonIcon icon={icons.soundcloud}></IonIcon>
              </IonRouterLink>
            </IonCol>
          )}
          {crateData?.StreamBeatsource && (
            <IonCol>
              <IonRouterLink
                className={'cloud-crate-item'}
                onClick={() =>
                  openExternalLink(crateData.StreamBeatsource || '')
                }
                style={{ cursor: 'pointer' }}
              >
                <IonIcon icon={icons.beatsource}></IonIcon>
              </IonRouterLink>
            </IonCol>
          )}
          {crateData?.StreamDeezer && (
            <IonCol>
              <IonRouterLink
                className={'cloud-crate-item'}
                onClick={() => openExternalLink(crateData.StreamDeezer || '')}
                style={{ cursor: 'pointer' }}
              >
                <IonIcon icon={icons.deezer}></IonIcon>
              </IonRouterLink>
            </IonCol>
          )}
          {crateData?.StreamTidal && (
            <IonCol>
              <IonRouterLink
                className={'cloud-crate-item'}
                onClick={() => openExternalLink(crateData.StreamTidal || '')}
                style={{ cursor: 'pointer' }}
              >
                <IonIcon icon={icons.tidal}></IonIcon>
              </IonRouterLink>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      {/* Edit Cloud Crates Modal */}
      <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList lines="none">
                <IonListHeader>
                  <IonLabel>Edit Streaming Links</IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonText>
                    You can use{' '}
                    <IonRouterLink
                      onClick={() => openExternalLink('https://soundiiz.com/')}
                    >
                      Soundiiz
                    </IonRouterLink>{' '}
                    to copy a playlist between platforms.
                  </IonText>
                </IonItem>
                <IonItem>
                  <IonLabel position="fixed">Spotify</IonLabel>
                  <IonInput
                    value={streamingLinks.StreamSpotify}
                    placeholder="Enter Spotify Link"
                    onIonChange={(e) =>
                      handleInputChange('StreamSpotify', e.detail.value!)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="fixed">Apple Music</IonLabel>
                  <IonInput
                    value={streamingLinks.StreamAppleMusic}
                    placeholder="Enter Apple Music Link"
                    onIonChange={(e) =>
                      handleInputChange('StreamAppleMusic', e.detail.value!)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="fixed">SoundCloud</IonLabel>
                  <IonInput
                    value={streamingLinks.StreamSoundcloud}
                    placeholder="Enter SoundCloud Link"
                    onIonChange={(e) =>
                      handleInputChange('StreamSoundcloud', e.detail.value!)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="fixed">Beatsource</IonLabel>
                  <IonInput
                    value={streamingLinks.StreamBeatsource}
                    placeholder="Enter Beatsource Link"
                    onIonChange={(e) =>
                      handleInputChange('StreamBeatsource', e.detail.value!)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="fixed">Deezer</IonLabel>
                  <IonInput
                    value={streamingLinks.StreamDeezer}
                    placeholder="Enter Deezer Link"
                    onIonChange={(e) =>
                      handleInputChange('StreamDeezer', e.detail.value!)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="fixed">Tidal</IonLabel>
                  <IonInput
                    value={streamingLinks.StreamTidal}
                    placeholder="Enter Tidal Link"
                    onIonChange={(e) =>
                      handleInputChange('StreamTidal', e.detail.value!)
                    }
                  />
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonButton
                expand="block"
                fill="outline"
                onClick={handleSaveLinks}
              >
                Save
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton
                expand="block"
                fill="outline"
                color="danger"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
    </>
  );
};
