import { useEffect, useState } from 'react';
import { getIcons } from '../components/Icons';

export const useIcons = () => {
  const [icons, setIcons] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadIcons = async () => {
      const iconPaths = await getIcons();
      setIcons(iconPaths);
      setLoading(false);
    };
    loadIcons();
  }, []);

  return { icons, loading };
};
