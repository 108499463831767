import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { alertOutline, caretDownOutline, caretUpOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFile,
  getTrackMatches,
  selectIsTrackLoadingMatches,
  selectShowMatcher,
  selectTrackMatches,
  setSelectedTrackMatches,
  setTrackMatches,
} from '../../store/slices/track-matcher.slice';
import { iTrack } from '../../types/ITrack';
import isElectron from '../../utils/isElectron';
import Pools from '../pools/Pools';
import Waveform from '../waveform/Waveform';
import './Tracks.scss';

import { TrackAvailabilityOptionDto } from '@cratehackers/api-client';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AppDispatch } from '../../store/store';
import { TrackDropdown } from '../trackDropdown/TrackDropdown';
import './Track.scss';
import { TrackDetailsRow } from './TrackDetailsRow';

interface ElectronFile extends File {
  path: string;
}

interface iLocalFile {
  filePath: string;
  id: number;
  importSources?: string;
  tags?: Record<string, any>;
  isVideo?: boolean;
  isSelected?: boolean;
}

const handleDragOver = (event: React.DragEvent<HTMLIonInputElement>) => {
  event.preventDefault();
  event.stopPropagation();
};

export const Track: React.FC<
  iTrack & {
    trackNum?: number;
    editable?: boolean;
    initiallyExpanded?: boolean;
    onDelete?: (track: iTrack) => void;
    resourceOptions: TrackAvailabilityOptionDto[];
    streamingOptions: TrackAvailabilityOptionDto[];
    purchaseOptions: TrackAvailabilityOptionDto[];
    cloudStorageOptions: TrackAvailabilityOptionDto[];
    showTrackInfo?: boolean;
  }
> = (props) => {
  const {
    ID: trackId,
    Artist,
    Title,
    PreviewURL,
    unmixable,
    editable,
    resourceOptions,
    purchaseOptions,
    streamingOptions,
    cloudStorageOptions,
    trackNum,
    showTrackInfo = true,
    expanded = false,
    onDelete,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const showMatcher = useSelector(selectShowMatcher);
  const matchedTracks = useSelector((s: any) => selectTrackMatches(s, trackId));
  const isLoading = useSelector((s: any) =>
    selectIsTrackLoadingMatches(s, trackId),
  );
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [currentDropdown, setCurrentDropdown] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const currentTrackDetails = useRef({ artist: Artist, title: Title, trackId });
  const [isInView, setIsInView] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: trackId, disabled: !editable });

  // Handles the tracks sliding up and down when a different track is dragged over top
  const sortingAnimationStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    // Updates the track order of the moving tracks (--is-being-dragged is the track being dragged, all other tracks will either increase or decrease by an order of 1)
    '--track-order':
      transform?.y && trackNum
        ? `var(--is-being-dragged, "${transform?.y > 0 ? trackNum + 1 : trackNum - 1}")`
        : undefined,
  };

  const trackRef = useRef(null);

  // Update the dropdown handling
  const handleExpandedDropdownChange = (dropdown: string) => {
    // Tracks is handled slightly differently since it uses the isExpanded property
    // It needs an additional property because it can also be controlled by the (show track matcher) toggle at the top of the crate
    if (dropdown === 'tracks') {
      setCurrentDropdown(dropdown);
      setIsExpanded((expanded) => !expanded);
      return;
    } else {
      // If a different dropdown is selected, collapse the tracks dropdown
      setIsExpanded(false);
    }
    setCurrentDropdown(dropdown === currentDropdown ? '' : dropdown);
  };

  // Update the selection handling
  const handleSelectionChange = useCallback(
    (matchId: number, isSelected: boolean) => {
      dispatch(setSelectedTrackMatches({ trackId, matchId, isSelected }));
    },
    [dispatch, trackId],
  );

  // Expand or collapse based on the "Show Track Matcher" toggle
  useEffect(() => {
    setIsExpanded(showMatcher);
  }, [showMatcher]);

  // Utility function to load track matches
  const loadTrackMatches = useCallback(async () => {
    if (!isElectron()) {
      return;
    }

    if (Artist || Title) {
      const response = await dispatch(
        getTrackMatches({ id: trackId, artist: Artist, title: Title }),
      ).unwrap();
      // If there's already a request in progress to retrieve matches then don't do anything
      if (!response) return;

      await dispatch(setTrackMatches({ trackId, files: response }));

      setIsExpanded(true);
      setCurrentDropdown('tracks');
    }
  }, [Artist, Title, trackId, dispatch]);

  // Reset state when track info changes
  useEffect(() => {
    const {
      artist: currentArtist,
      title: currentTitle,
      trackId: currentTrackId,
    } = currentTrackDetails.current;

    if (
      Artist !== currentArtist ||
      Title !== currentTitle ||
      trackId !== currentTrackId
    ) {
      currentTrackDetails.current = {
        artist: Artist,
        title: Title,
        trackId,
      };
      loadTrackMatches();
    }
  }, [Artist, Title, trackId, loadTrackMatches]);

  // TODO: We should remember the selected matched track for each crates track
  // Effect to handle automatic checkbox check based on PreviewURL
  useEffect(() => {
    if (matchedTracks?.length && PreviewURL) {
      matchedTracks.forEach((track) => {
        if (!track.isSelected && track.filePath === PreviewURL) {
          handleSelectionChange(track.id, true);
        }
      });
    }
  }, [matchedTracks, PreviewURL, handleSelectionChange]);

  // Dispatches to API to add file and auto-select it
  const addLocalFile = (filePath: string): void => {
    if (filePath.length === 0) return;
    const newTrack: iLocalFile = {
      filePath,
      id: new Date().getTime() * filePath.length, // Temp for now. Low collision
    };

    dispatch(addFile({ file: newTrack, trackId }));
  };

  // Handles events from the DOM for adding files (button or drag-n-drop)
  const handleAddFiles = (files: FileList | null): void => {
    if (!files?.length) {
      return;
    }

    Array.from(files).forEach((file) => {
      const electronFile = file as ElectronFile;
      if (!electronFile?.path) {
        return;
      }
      addLocalFile(electronFile.path);
    });
  };

  // Keeps track of when the track is in the viewport. Used to only load matches that are on screen
  useEffect(() => {
    const trackElement = trackRef.current;
    if (!trackElement) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      {
        root: null,
        threshold: 0.1,
      },
    );

    observer.observe(trackElement);

    return () => {
      if (trackElement) {
        observer.unobserve(trackElement);
      }
    };
  }, []);

  // Initial load of track matches, only happens if the track is in the viewport, the dropdown is expanded,
  // and the tracks haven't already been loaded
  useEffect(() => {
    if (isInView && isExpanded && !matchedTracks) {
      loadTrackMatches();
    }
  }, [loadTrackMatches, isExpanded, isInView, matchedTracks]);

  return (
    <div
      className={trackNum === 1 ? 'track first' : 'track'}
      ref={setNodeRef}
      style={sortingAnimationStyle}
      {...attributes}
    >
      <IonRow
        class={`${unmixable ? 'unmixable' : ''}`}
        data-id={trackId}
        onDragOver={handleDragOver}
        onDrop={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleAddFiles(event.dataTransfer.files);
        }}
        ref={trackRef}
      >
        <IonGrid fixed={true}>
          {showTrackInfo && (
            <TrackDetailsRow
              {...props}
              onDeleteTrack={(event) => {
                onDelete?.(props);
                event.preventDefault();
                event.stopPropagation();
              }}
              dragListeners={listeners}
            />
          )}

          <IonRow className="actions" color="light">
            {isElectron() && (
              <IonCol size="auto" className="dropdown-button-container">
                <IonButton
                  onClick={() => handleExpandedDropdownChange('tracks')}
                  fill="clear"
                  className="dropdown-button-expander"
                >
                  Your Matching Files{' '}
                  {isLoading ? (
                    <IonSpinner slot="end" name="dots" />
                  ) : (
                    <>
                      {!!matchedTracks?.length && `(${matchedTracks?.length})`}
                      {currentDropdown === 'tracks' && isExpanded ? (
                        <IonIcon
                          className="dropdown-collapse-carrot"
                          slot="end"
                          icon={caretUpOutline}
                        ></IonIcon>
                      ) : (
                        <IonIcon
                          className="dropdown-expand-carrot"
                          slot="end"
                          icon={caretDownOutline}
                        ></IonIcon>
                      )}
                    </>
                  )}
                </IonButton>
              </IonCol>
            )}
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('pools')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Pools
                {currentDropdown === 'pools' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('streaming')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Streaming
                {currentDropdown === 'streaming' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('purchase')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Purchase
                {currentDropdown === 'purchase' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('resources')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Resources
                {currentDropdown === 'resources' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('cloud')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Cloud
                {currentDropdown === 'cloud' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            {isElectron() && (
              <IonCol size="auto" className="dropdown-button-container">
                <IonButton
                  fill="clear"
                  className="dropdown-button-expander"
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  + Add Local File
                </IonButton>
                <input
                  ref={fileInputRef}
                  type={'file'}
                  style={{ display: 'none' }}
                  onChange={(event) => handleAddFiles(event.target.files)}
                  multiple
                />
              </IonCol>
            )}
          </IonRow>
          {currentDropdown === 'pools' && (
            <Pools artist={Artist} title={Title} />
          )}
          {currentDropdown === 'streaming' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={streamingOptions}
            />
          )}
          {currentDropdown === 'purchase' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={purchaseOptions}
            />
          )}
          {currentDropdown === 'resources' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={resourceOptions}
            />
          )}
          {currentDropdown === 'cloud' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={cloudStorageOptions}
            />
          )}
          {isElectron() && (
            <>
              <div
                className={`track-matcher-container ${currentDropdown === 'tracks' && isExpanded ? 'open' : ''}`}
              >
                {matchedTracks && matchedTracks.length > 0 ? (
                  matchedTracks.map((track) => (
                    <IonRow
                      key={track.id + '-' + trackId}
                      className="track-matcher-container open"
                    >
                      <IonCol
                        size="12"
                        sizeMd="6"
                        sizeLg="6"
                        className="check-box"
                      >
                        <IonGrid>
                          <IonRow>
                            <IonCol size="auto">
                              <IonCheckbox
                                className="matched-track-checkbox"
                                labelPlacement="end"
                                checked={
                                  track.isSelected ||
                                  (!track.isSelected &&
                                    track.filePath === PreviewURL)
                                }
                                onIonChange={(e) =>
                                  handleSelectionChange(
                                    track.id,
                                    e.detail.checked,
                                  )
                                }
                              />
                            </IonCol>
                            <IonCol className="matched-track-checkbox-label">
                              {/*TODO: A great place to support tag updating. Eg: Set the year, set the album, composer, etc*/}
                              {/*Just open a modal when you click on BPM/Key, and focus on those fields.*/}
                              <div className="ion-text-wrap">
                                {track.tags?.title && track.tags.title}
                                {!!track.tags?.artist?.length &&
                                  track.tags?.title &&
                                  ' - '}
                                {!!track.tags?.artist?.length &&
                                  track.tags?.artist.join(', ')}
                              </div>
                              <IonText color="medium">
                                <sub>{track.filePath}</sub>
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol size="12" sizeMd="6" sizeLg="3">
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <IonRow>
                                <IonText color="primary">BPM</IonText>
                                <div className="audio-feature-container-small">
                                  {track.tags && track.tags.bpm
                                    ? track.tags.bpm
                                    : ''}
                                </div>
                              </IonRow>
                            </IonCol>
                            <IonCol>
                              <IonRow>
                                <IonText color="primary">Key</IonText>
                                <div className="audio-feature-container-small">
                                  {track.tags && track.tags.key
                                    ? track.tags.key
                                    : ''}
                                </div>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol
                        size="12"
                        sizeLg="3"
                        className="center-content preview-container"
                      >
                        <IonCol className="preview small-preview-container">
                          {track.filePath ? (
                            <Waveform height={20} url={track.filePath} />
                          ) : (
                            <IonLabel>No preview available</IonLabel>
                          )}
                        </IonCol>
                      </IonCol>
                    </IonRow>
                  ))
                ) : (
                  <IonRow>
                    <IonCol>
                      <div className="ion-text-wrap no-track-matches">
                        <IonIcon
                          aria-hidden="true"
                          slot="start"
                          src={alertOutline}
                        />
                        No matched tracks found. Click a local file, drag and
                        drop here, or use one of the other methods to find the
                        track.
                      </div>
                    </IonCol>
                  </IonRow>
                )}
              </div>
            </>
          )}
        </IonGrid>
      </IonRow>
    </div>
  );
};
