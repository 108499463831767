import {
  createReduxMiddleware,
  defaultOptions,
} from '@karmaniverous/serify-deserify';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { AudioPlayerSlice } from './slices/audio-player-state.slice';
import categoriesReducer from './slices/categories.slice';
import { CrateSlice } from './slices/crate.slice';
import djepTitleFormatSlice from './slices/djep-title-format.slice';
import { DriveStateSlice } from './slices/drive-state.slice';
import loading from './slices/loading.slice';
import { ThemeStateSlice } from './slices/theme-state.slice';
import toast from './slices/toast.slice';
import { TokenStateSlice } from './slices/token-state.slice';
import { trackMatcherSlice } from './slices/track-matcher.slice';
import { TrackSlice } from './slices/track.slice';

// Serify is used to serialize and deserialize un-serializable values automatically
// This is necessary for slices such as Crate which contain Date properties which are inherintely unserializable
const serifyMiddleware = createReduxMiddleware(defaultOptions);

const store = configureStore({
  reducer: {
    themeState: ThemeStateSlice.reducer,
    tokenState: TokenStateSlice.reducer,
    driveState: DriveStateSlice.reducer,
    categories: categoriesReducer,
    crate: CrateSlice.reducer,
    track: TrackSlice.reducer,
    audioPlayerState: AudioPlayerSlice.reducer,
    trackMatcherState: trackMatcherSlice.reducer,
    toast,
    loading,
    djepTitleFormat: djepTitleFormatSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(serifyMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
