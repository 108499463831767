import { TrackAvailabilityOptionDto } from '@cratehackers/api-client';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  SortableData,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';
import ApiService from '../../services/Api.service';
import { iTrack, iTracks } from '../../types/ITrack';
import { Track } from './Track';
import { TrackDragOverlay } from './TrackDragOverlay';
import './Tracks.scss';

interface FilePathMap {
  [trackId: string]: string[];
}

const BATCH_SIZE = 1; // Process 2 tracks concurrently

interface iLocalFile {
  filePath: string;
  id: number;
  importSources?: string;
  tags?: any;
  isVideo?: boolean;
  isSelected?: boolean;
}

export const Tracks: React.FC<
  Required<iTracks> & {
    editable: boolean;
    showTrackMatcher?: boolean;
    onDeleteTrack: (track: iTrack) => void;
    onReorderTracks: (tracks: iTrack[]) => void;
  }
> = ({
  tracks,
  editable,
  showTrackMatcher = true,
  onDeleteTrack,
  onReorderTracks,
}) => {
  const [orderedTrackIds, setTrackIds] = useState<string[]>([]);
  const [currentlyDraggingTrack, setCurrentlyDraggingTrack] = useState<
    iTrack | undefined
  >(undefined);
  const [currentlyDraggingIndex, setCurrentlyDraggingIndex] = useState<
    number | undefined
  >(undefined);
  const [selectedFiles, setSelectedFiles] = useState<FilePathMap>({});
  const [loadingState, setLoadingState] = useState({
    currentIndex: 0,
    total: 0,
    isComplete: false,
    processingTracks: new Set<string>(),
    initialized: false, // Add this to track initial setup
  });

  const [purchaseOptions, setPurchaseOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);
  const [streamingOptions, setStreamingOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);
  const [cloudStorageOptions, setCloudStorageOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);
  const [resourceOptions, setResourceOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);

  useEffect(() => {
    // TODO: Maybe return this data naturally with the tracks?
    const fetchTrackAvailabilityOptions = async () => {
      try {
        const data = await ApiService.getTrackAvailabilityOptions();
        setPurchaseOptions(data.purchases);
        setStreamingOptions(data.streaming);
        setCloudStorageOptions(data.cloudStorage);
        setResourceOptions(data.resources);
      } catch (error) {
        console.error('Error fetching track dropdowns', error);
      }
    };

    fetchTrackAvailabilityOptions();
  }, []);

  // trackIds is used by the drag-n-drop library to keep track of what order the tracks are in
  useEffect(() => {
    setTrackIds(tracks.map((track) => track.ID));
  }, [tracks]);

  // Modify the initial setup effect
  useEffect(() => {
    if (showTrackMatcher && tracks && !loadingState.initialized) {
      setLoadingState({
        currentIndex: 0,
        total: tracks.length,
        isComplete: false,
        processingTracks: new Set(),
        initialized: true, // Mark as initialized
      });
    } else if (!showTrackMatcher) {
      // Reset when matcher is disabled
      setLoadingState({
        currentIndex: 0,
        total: 0,
        isComplete: false,
        processingTracks: new Set(),
        initialized: false,
      });
    }
  }, [showTrackMatcher, tracks]);

  // const processTrack = useCallback(async (track: iTrack): Promise<void> => {
  //   const trackId =
  //     track.ID ||
  //     sha256(
  //       JSON.stringify({ artist: track.Artist, title: track.Title }),
  //     ).toString();
  //
  //   // Only process if not already processing this track
  //   if (loadingState.processingTracks.has(trackId)) {
  //     return Promise.resolve();
  //   }
  //
  //   return new Promise((resolve) => {
  //     const listener = (response: any) => {
  //       setLoadingState((prev) => ({
  //         ...prev,
  //         processingTracks: new Set(
  //           Array.from(prev.processingTracks).filter((id) => id !== trackId),
  //         ),
  //       }));
  //       resolve();
  //     };
  //
  //     setLoadingState((prev) => ({
  //       ...prev,
  //       processingTracks: new Set([
  //         ...Array.from(prev.processingTracks),
  //         trackId,
  //       ]),
  //     }));
  //
  //     window.api?.once(`getTrackMatches:${trackId}`, listener);
  //
  //     run('getTrackMatches', {
  //       artist: track.Artist,
  //       title: track.Title,
  //       id: trackId,
  //     });
  //   });
  // }, []);

  // const processNextBatch = useCallback(async () => {
  //   console.log(
  //     'processNextBatch:',
  //     loadingState.currentIndex,
  //     loadingState.initialized,
  //   );
  //
  //   if (
  //     !tracks ||
  //     loadingState.currentIndex >= tracks.length ||
  //     !loadingState.initialized
  //   ) {
  //     if (loadingState.currentIndex >= (tracks?.length || 0)) {
  //       setLoadingState((prev) => ({ ...prev, isComplete: true }));
  //     }
  //     return;
  //   }
  //
  //   const batchEnd = Math.min(
  //     loadingState.currentIndex + BATCH_SIZE,
  //     tracks.length,
  //   );
  //   const currentBatch = tracks.slice(loadingState.currentIndex, batchEnd);
  //
  //   for (let index = 0; index < currentBatch.length; index++) {
  //     const track = currentBatch[index];
  //     await processTrack(track); // Process each track sequentially
  //   }
  //
  //   setLoadingState((prev) => ({
  //     ...prev,
  //     currentIndex: batchEnd,
  //     isComplete: batchEnd >= tracks.length,
  //   }));
  // }, [
  //   tracks,
  //   loadingState.currentIndex,
  //   loadingState.initialized,
  //   processTrack,
  // ]);

  // Update the effect that watches for batch processing
  // useEffect(() => {
  //   if (
  //     showTrackMatcher &&
  //     tracks &&
  //     loadingState.initialized &&
  //     loadingState.currentIndex < tracks.length &&
  //     !loadingState.isComplete &&
  //     loadingState.processingTracks.size === 0 // Only process next batch when current batch is done
  //   ) {
  //     processNextBatch();
  //   }
  // }, [
  //   loadingState.currentIndex,
  //   loadingState.processingTracks,
  //   loadingState.initialized,
  //   tracks,
  //   showTrackMatcher,
  //   processNextBatch,
  // ]);

  // Pass loading state to Track component

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    setCurrentlyDraggingTrack(undefined);

    if (!over) {
      return;
    }

    const originalPosition: SortableData['sortable'] | undefined =
      active.data.current?.sortable;
    const newPosition: SortableData['sortable'] | undefined =
      over.data.current?.sortable;

    if (!originalPosition || !newPosition) {
      return;
    }

    // The item is being dropped over a new item so the array needs to be re-ordered
    if (active.id !== over.id) {
      const oldIndex = originalPosition.index;
      const newIndex = newPosition.index;
      onReorderTracks(arrayMove(tracks, oldIndex, newIndex));
    }
  };

  // Used to display the drag overlay
  const handleDragStart = ({ active }: DragStartEvent): void =>
    setCurrentlyDraggingTrack(tracks.find((item) => item.ID === active.id));

  // Updates the track order for overlay of dragged track
  const handleDragOver = ({ over }: DragEndEvent): void => {
    const overItem: SortableData['sortable'] | undefined =
      over?.data.current?.sortable;
    if (overItem === undefined) {
      setCurrentlyDraggingIndex(undefined);
    } else {
      setCurrentlyDraggingIndex(overItem.index + 1);
    }
  };

  return (
    <div className={currentlyDraggingTrack ? 'no-highlight' : ''}>
      {tracks.length > 0 && (
        <DndContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          autoScroll={true}
        >
          <SortableContext
            items={orderedTrackIds}
            strategy={verticalListSortingStrategy}
          >
            {tracks.map((track, index) => (
              <div
                key={track.ID}
                // Handles updating the track order display when dragging a track
                style={
                  {
                    '--is-being-dragged':
                      track.ID === currentlyDraggingTrack?.ID
                        ? `"${currentlyDraggingIndex}"`
                        : undefined,
                  } as any
                }
              >
                <Track
                  onDelete={onDeleteTrack}
                  trackNum={index + 1}
                  editable={editable}
                  initiallyExpanded={showTrackMatcher}
                  resourceOptions={resourceOptions}
                  streamingOptions={streamingOptions}
                  purchaseOptions={purchaseOptions}
                  cloudStorageOptions={cloudStorageOptions}
                  {...track}
                />
              </div>
            ))}
          </SortableContext>
          {/* The overlay displayed at the mouse position when dragging an item */}
          <DragOverlay className="drag-overlay">
            {currentlyDraggingTrack ? (
              <TrackDragOverlay
                {...currentlyDraggingTrack}
                trackNum={currentlyDraggingIndex}
              ></TrackDragOverlay>
            ) : null}
          </DragOverlay>
        </DndContext>
      )}
    </div>
  );
};
