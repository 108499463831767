import { logout } from './logout';

const fetchWrapper = async (
  url: string,
  options: RequestInit = {},
): Promise<any> => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      if (response.status === 401) {
        console.error('Unauthorized access - Bubble token invalid');
        logout();
      } else if (response.status === 500) {
        console.error('Server error');
        if (history) {
          logout(); // TODO: Move away from this to avoid missing errors
        }
      } else {
        console.error(`Error: ${response.statusText}`);
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Network error or no response received');
  }
};

export default fetchWrapper;
