import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import buildStyle from '../../components/myCrates/build-paths/build-path.module.scss';
import DjepPath from '../../components/myCrates/build-paths/djep/djep';
import FilePath from '../../components/myCrates/build-paths/file/file';
import GenrePath from '../../components/myCrates/build-paths/genre/genre';
import HomePath from '../../components/myCrates/build-paths/home/home';
import SeratoPath from '../../components/myCrates/build-paths/serato/serato';
import SmplPath from '../../components/myCrates/build-paths/smpl/smpl';
import SpotifyPath from '../../components/myCrates/build-paths/spotify/spotify';
import TextPath from '../../components/myCrates/build-paths/text/text';
import TrackPath from '../../components/myCrates/build-paths/track/track';
import BuildProgressIndicator from '../../components/myCrates/build-progress-indicator/build-progress-indicator';
import BuildSelection from '../../components/myCrates/build-selection/build-selection';
import { useIcons } from '../../hooks/useIcons';
import crateStyle from './create-crate.module.scss';

export default function CreateCrate() {
  const { pathname } = useLocation();
  const { icons } = useIcons();
  const pathComponents: any = useMemo(
    () => ({
      '/my-crates/home': <HomePath />,
      '/my-crates/track': <TrackPath />,
      '/my-crates/spotify': <SpotifyPath />,
      '/my-crates/text': <TextPath />,
      '/my-crates/file': <FilePath />,
      '/my-crates/serato': <SeratoPath />,
      '/my-crates/genre': <GenrePath />,
      '/my-crates/smpl': <SmplPath />,
      '/my-crates/djep': <DjepPath />,
    }),
    [],
  );

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="4" sizeSm="12" sizeLg="3">
              <div className={crateStyle['page-header']}>
                <h1 className={crateStyle['title']}>
                  <IonIcon
                    icon={icons.myCrates}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  Crate Builder
                </h1>
              </div>
            </IonCol>
            <IonCol sizeXs="8" sizeSm="12" sizeLg="7">
              <div className={crateStyle['step-header']}>
                {pathname !== '/my-crates' ? (
                  <BuildProgressIndicator
                    currentStep={2}
                    firstStepName={`Start from ${formatPathname(pathname)}`}
                  />
                ) : null}
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol sizeXs="12" sizeLg="3" style={{ padding: '0 10px' }}>
              <div className={`${crateStyle['crate-selections']}`}>
                <BuildSelection />
              </div>
            </IonCol>

            <IonCol sizeXs="12" sizeLg="9" style={{ padding: '0 10px' }}>
              <div>
                <div className={`${buildStyle['build-path']} `}>
                  {pathComponents[pathname]}
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );

  function formatPathname(pathname: string) {
    const formattedPathname = pathname.replace('/my-crates/', '');
    return (
      formattedPathname.charAt(0).toUpperCase() + formattedPathname.slice(1)
    );
  }
}
