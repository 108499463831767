/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PaginationMetaDto interface.
 */
export function instanceOfPaginationMetaDto(value) {
    if (!('itemCount' in value) || value['itemCount'] === undefined)
        return false;
    if (!('itemsPerPage' in value) || value['itemsPerPage'] === undefined)
        return false;
    if (!('pagesLeft' in value) || value['pagesLeft'] === undefined)
        return false;
    if (!('currentPage' in value) || value['currentPage'] === undefined)
        return false;
    return true;
}
export function PaginationMetaDtoFromJSON(json) {
    return PaginationMetaDtoFromJSONTyped(json, false);
}
export function PaginationMetaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'itemCount': json['itemCount'],
        'itemsPerPage': json['itemsPerPage'],
        'pagesLeft': json['pagesLeft'],
        'currentPage': json['currentPage'],
    };
}
export function PaginationMetaDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'itemCount': value['itemCount'],
        'itemsPerPage': value['itemsPerPage'],
        'pagesLeft': value['pagesLeft'],
        'currentPage': value['currentPage'],
    };
}
