/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ApplicationTokenDto interface.
 */
export function instanceOfApplicationTokenDto(value) {
    if (!('application' in value) || value['application'] === undefined)
        return false;
    if (!('accessToken' in value) || value['accessToken'] === undefined)
        return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined)
        return false;
    return true;
}
export function ApplicationTokenDtoFromJSON(json) {
    return ApplicationTokenDtoFromJSONTyped(json, false);
}
export function ApplicationTokenDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'application': json['application'],
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
    };
}
export function ApplicationTokenDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'application': value['application'],
        'accessToken': value['accessToken'],
        'refreshToken': value['refreshToken'],
    };
}
