import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { Toast } from '../../components/ToastManager/types';

type ToastState = {
  toastQueue: Toast[];
};

const initialState: ToastState = {
  toastQueue: [],
};

export const ToastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, { payload: toast }: PayloadAction<Omit<Toast, 'id'>>) => {
      const newToast: Toast = {
        id: uniqueId('toast'),
        ...toast,
      };
      // Pushes new toast to the end of the existing queue
      state.toastQueue = [...state.toastQueue, newToast];
      return state;
    },
    removeToast: ({ toastQueue }) => {
      // Removes the toast from the front of the queue
      const removedToast = toastQueue.shift();

      if (!removedToast) {
        return;
      }

      // If the removed toast has an onHide callback trigger it now
      removedToast?.onHide?.();

      // If a new toast is now at the front, call its onShow callback
      if (toastQueue[0]) {
        toastQueue[0].onShow?.();
      }
    },
  },
});

/**
 * Gets the toast from the front of the queue, if it's empty it is null
 */
export const selectCurrentToast = (state: { toast: ToastState }) => {
  const toastQueue = state.toast.toastQueue;
  return toastQueue.length ? toastQueue[0] : null;
};

export const { addToast, removeToast } = ToastSlice.actions;

export default ToastSlice.reducer;
