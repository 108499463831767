import { IonLoading } from '@ionic/react';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../store/slices/loading.slice';

/**
 * A global spinner that is shown whenever isLoading is set to true from the useLoading hook
 */
export const RootLoader = () => {
  const isLoading = useSelector(selectIsLoading);

  return (
    <IonLoading
      isOpen={isLoading}
      spinner="crescent"
      style={{ '--background': 'none' }}
    />
  );
};
