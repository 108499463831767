import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { iCategory } from '../../types/ICategory';
import './Categories.scss';
import apiService from '../../services/Api.service';
import { CrateCategoryDto } from '@cratehackers/api-client';
import {
  saveCrateChanges,
  selectCrateState,
  selectSelectedCrate,
  setSelectedCrateCategories,
} from '../../store/slices/crate.slice';
import { AppDispatch } from '../../store/store';

export const CategoriesEditable = () => {
  const [availableCategories, setAvailableCategories] = useState<iCategory[]>(
    [],
  );
  const dispatch = useDispatch<AppDispatch>();
  const selectedCrate = useSelector(selectSelectedCrate);
  const { isEditable } = useSelector(selectCrateState);

  useEffect(() => {
    const chApi = apiService.getApiClient();
    chApi
      .crateControllerGetCrateCategories()
      .then((categories: CrateCategoryDto[]) => {
        const formattedCategories = categories.map((cat) => ({
          _id: cat.id,
          Category: cat.category,
        }));
        setAvailableCategories(formattedCategories);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Sync categories when selectedCrate changes
  const selectedCategoryIds =
    selectedCrate?.Category?.map((cat) => cat._id) || [];

  const handleCategoryChange = async (selectedIds: string[]) => {
    const selectedCategories = availableCategories.filter((cat) =>
      selectedIds.includes(cat._id!),
    );

    if (selectedCrate) {
      dispatch(setSelectedCrateCategories(selectedCategories));
      await dispatch(saveCrateChanges());
    }
  };

  return (
    <IonItem>
      {isEditable ? (
        <IonSelect
          multiple
          value={selectedCategoryIds}
          placeholder="Select Categories"
          onIonChange={(e) => handleCategoryChange(e.detail.value)}
        >
          {availableCategories.map((category) => (
            <IonSelectOption key={category._id} value={category._id}>
              {category.Category}
            </IonSelectOption>
          ))}
        </IonSelect>
      ) : (
        selectedCrate?.Category?.map((category, index) => (
          <span key={category._id}>
            {category.Category}
            {selectedCrate.Category &&
              index < selectedCrate.Category.length - 1 &&
              ', '}
          </span>
        ))
      )}
    </IonItem>
  );
};
