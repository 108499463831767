import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { once, run } from '../../helper/electron';
import {
  FilePathMap,
  iLocalFile,
  iLocalFileAPIResponse,
} from '../../types/ILocalFile';
import { RootState } from '../store';

interface TrackMatcherState {
  showMatcher: boolean;
  trackMatches: {
    [trackId: string]: iLocalFile[];
  };
  selectedMatches: FilePathMap;
  currentRequests: {
    [trackId: string]: string | null;
  };
}

const initialState: TrackMatcherState = {
  showMatcher: true,
  trackMatches: {},
  selectedMatches: {},
  currentRequests: {},
};

const removeUndefined = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefined); // Recursively process array elements
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => [key, removeUndefined(value)]), // Recursively process nested objects
    );
  }
  return obj;
};

/**
 * Retrieves track matches from local computer via Electron
 */
export const addFile = createAsyncThunk<
  { file: iLocalFile; trackId: string },
  { file: iLocalFile; trackId: string }
>('trackMatcher/addTrack', async ({ file, trackId }, thunkAPI) => {
  try {
    run('my-music/scan-track', { track: file });
    const newFile = await new Promise<iLocalFile>((resolve) =>
      once(`my-music/scan-track:${file.id}`, resolve),
    );
    const parsedFile = parseTags([newFile])[0];
    const cleanedParsedFile = removeUndefined(parsedFile) as iLocalFile;
    return thunkAPI.fulfillWithValue({ file: cleanedParsedFile, trackId });
  } catch (err) {
    return thunkAPI.rejectWithValue((err as Error).toString());
  }
});

/**
 * Retrieves track matches from local computer via Electron
 */
export const getTrackMatches = createAsyncThunk<
  iLocalFileAPIResponse[] | null,
  { artist: string; title: string; id: string },
  {
    state: {
      trackMatcherState: {
        currentRequests: {
          [trackId: string]: string | null;
        };
      };
    };
  }
>('trackMatcher/getTrackMatches', async ({ artist, title, id }, thunkAPI) => {
  if (
    thunkAPI.requestId !==
    thunkAPI.getState().trackMatcherState.currentRequests[id]
  ) {
    return null;
  }

  try {
    run('getTrackMatches', { artist, title, id });
    const response = await new Promise<iLocalFile[]>((resolve) =>
      once(`getTrackMatches:${id}`, resolve),
    );
    return thunkAPI.fulfillWithValue(response);
  } catch (err) {
    return thunkAPI.rejectWithValue((err as Error).toString());
  }
});

const parseTags = (files: iLocalFileAPIResponse[]): iLocalFile[] =>
  files.map((file) => ({
    ...file,
    tags: typeof file.tags === 'string' ? JSON.parse(file.tags) : file.tags,
  }));

export const trackMatcherSlice = createSlice({
  name: 'trackMatcher',
  initialState,
  reducers: {
    setShowMatcher: (state, action: PayloadAction<boolean>) => {
      state.showMatcher = action.payload;
    },
    setTrackMatches: (
      state,
      action: PayloadAction<{
        trackId: string;
        files: iLocalFileAPIResponse[] | undefined;
      }>,
    ) => {
      // TODO: Implement on Track Matcher IPC response
      const { trackId, files } = action.payload;
      // Can be used to clear the state to re-load matches
      if (!files) {
        delete state.trackMatches[trackId];
        return;
      }

      const parsedFiles = parseTags(files);

      state.trackMatches[trackId] = parsedFiles;
    },
    clearTrackMatches: (state) => {
      state.selectedMatches = {};
      state.trackMatches = {};
    },
    setSelectedTrackMatches: (
      state,
      action: PayloadAction<{
        trackId: string;
        matchId: number;
        isSelected: boolean;
      }>,
    ) => {
      const { trackId, matchId, isSelected } = action.payload;
      const updatedMatches =
        state.trackMatches[trackId]?.map((match) =>
          match.id === matchId ? { ...match, isSelected } : match,
        ) ?? [];

      state.trackMatches[trackId] = updatedMatches;

      const selectedPaths = updatedMatches
        .filter((match) => match.isSelected && match.filePath.length > 0)
        .map((match) => match.filePath);

      state.selectedMatches[trackId] = selectedPaths;
    },
    selectFirstMatches: (state) => {
      console.log('selectFirstMatches:', Object.entries(state.trackMatches));
      Object.entries(state.trackMatches).forEach(([trackId, files]) => {
        if (files.length > 0) {
          state.selectedMatches[trackId] = [files[0].filePath];
        }
      });
    },
    selectAllMatches: (state) => {
      Object.entries(state.trackMatches).forEach(([trackId, files]) => {
        if (files.length > 0) {
          state.selectedMatches[trackId] = files.map((f) => f.filePath);
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackMatches.fulfilled, (state, action) => {
      if (action.payload) {
        state.currentRequests[action.meta.arg.id] = null;
      }
    });
    builder.addCase(getTrackMatches.pending, (state, action) => {
      if (!state.currentRequests[action.meta.arg.id]) {
        state.currentRequests[action.meta.arg.id] = action.meta.requestId;
      }
    });
    builder.addCase(getTrackMatches.rejected, (state, action) => {
      state.currentRequests[action.meta.arg.id] = null;
    });
    builder.addCase(addFile.fulfilled, (state, action) => {
      const { trackId, file } = action.payload;

      // Initialize trackMatches if it doesn't exist
      if (!state.trackMatches[trackId]) {
        state.trackMatches[trackId] = []; // Initialize as an empty array
      }

      // Initialize selectedMatches if it doesn't exist
      if (!state.selectedMatches[trackId]) {
        state.selectedMatches[trackId] = []; // Initialize as an empty array
      }

      // Add the new file to trackMatches
      state.trackMatches[trackId].push({
        ...file,
        isSelected: true,
      });

      // Add the file path to selectedMatches
      state.selectedMatches[trackId].push(file.filePath);
    });
  },
});

export const {
  setShowMatcher,
  setTrackMatches,
  setSelectedTrackMatches,
  selectFirstMatches,
  selectAllMatches,
  clearTrackMatches,
} = trackMatcherSlice.actions;

// Memoized selectors
export const selectShowMatcher = (state: RootState) =>
  state.trackMatcherState.showMatcher;

const selectTrackMatcherState = (state: RootState) => state.trackMatcherState;
const selectTrackId = (_state: RootState, trackId: string) => trackId;

// Memoized selectors
export const selectSelectedMatches = () => {
  return createSelector(
    [selectTrackMatcherState],
    (state: TrackMatcherState) => state.selectedMatches,
  );
};

export const selectTrackMatches = (
  state: { trackMatcherState: TrackMatcherState },
  trackId: string,
): iLocalFile[] | undefined => state.trackMatcherState.trackMatches?.[trackId];

export const selectIsTrackLoadingMatches = (
  state: { trackMatcherState: TrackMatcherState },
  trackId: string,
): boolean => !!state.trackMatcherState.currentRequests[trackId];

export default trackMatcherSlice.reducer;
