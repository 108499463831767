import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import WaveSurfer from 'wavesurfer.js';
import './Waveform.scss';
import { IonIcon } from '@ionic/react';
import { pause, play } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useDispatch } from '../../store/hooks';
import {
  setPlaying,
  setURL,
} from '../../store/slices/audio-player-state.slice';

interface WaveformProps {
  url: string;
  height: number;
  changeTrack?: (direction: string) => void;
  autoplay?: boolean;
}

export interface WaveformHandle {
  play: () => void;
  moveToEnd: () => void;
  moveToStart: () => void;
}

const Waveform = forwardRef<WaveformHandle, WaveformProps>(
  ({ url, height, changeTrack, autoplay = false }, ref) => {
    if (!url || typeof url !== 'string') {
      return null;
    }

    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const dispatch = useDispatch();

    const currentPreviewURL = useSelector(
      (state: RootState) => state.audioPlayerState.url,
    );
    const audioIsPlaying = useSelector(
      (state: RootState) => state.audioPlayerState.isPlaying,
    );

    // Append file protocol
    url = url.startsWith('/') ? `file://${url}` : url;
    const initializeWaveSurfer = () => {
      if (isInitialized || !waveformRef.current) return;

      setLoading(true);
      setIsInitialized(true);
      setReady(false);

      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'gray',
        progressColor: 'white',
        cursorColor: 'white',
        cursorWidth: 2,
        height: height,
        barGap: 0.75,
        barWidth: 0.75,
        fillParent: true,
        dragToSeek: true,
      });

      // Register events
      wavesurfer.current!.on('ready', () => {
        setLoading(false);
        setReady(true);
        if (currentPreviewURL === url) {
          dispatch(setPlaying(true));
          wavesurfer.current!.play();
        }
      });

      wavesurfer.current.on('finish', () => {
        if (autoplay && changeTrack) {
          changeTrack('next');
        }
        wavesurfer.current!.seekTo(0);
        dispatch(setPlaying(false));
      });

      console.log('LOADING:', url);
      try {
        wavesurfer.current.load(url);
      } catch (e) {
        console.log('Unable to load track waveform:', url);
      }
    };

    // Expose method to initialize and start playback
    useImperativeHandle(ref, () => ({
      play() {
        initializeWaveSurfer();
        if (wavesurfer.current && ready) {
          wavesurfer.current.play();
        }
      },
      moveToStart() {
        if (wavesurfer.current && ready) {
          wavesurfer.current?.seekTo(0);
        }
      },
      moveToEnd() {
        if (wavesurfer.current && ready) {
          wavesurfer.current?.seekTo(100);
        }
      },
    }));

    useEffect(() => {
      if (audioIsPlaying && currentPreviewURL === url && ready) {
        wavesurfer.current!.play();
      } else {
        wavesurfer.current?.pause();
      }
    }, [audioIsPlaying, ready, currentPreviewURL]);

    const handlePlayPause = () => {
      if (audioIsPlaying) {
        dispatch(setPlaying(false));
      } else {
        dispatch(setURL(url));
        dispatch(setPlaying(true));
        initializeWaveSurfer();
      }
    };

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (currentPreviewURL !== url) return;
        if (event.code === 'Space') {
          event.preventDefault();
          handlePlayPause();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [url, audioIsPlaying, currentPreviewURL]);

    useEffect(() => {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: 'Audio Title',
          artist: 'Artist Name',
        });

        navigator.mediaSession.setActionHandler('play', () => {
          dispatch(setPlaying(true));
        });

        navigator.mediaSession.setActionHandler('pause', () => {
          dispatch(setPlaying(false));
        });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
          if (changeTrack) {
            changeTrack('previous');
          }
        });

        navigator.mediaSession.setActionHandler('nexttrack', () => {
          if (changeTrack) {
            changeTrack('next');
          }
        });
      }
    }, [audioIsPlaying]);

    useEffect(() => {
      return () => {
        wavesurfer.current?.destroy();
      };
    }, []);

    return (
      <div className="waveform-container">
        {audioIsPlaying && currentPreviewURL === url ? (
          <IonIcon
            icon={pause}
            onClick={() => dispatch(setPlaying(false))}
          ></IonIcon>
        ) : (
          <IonIcon
            icon={play}
            onClick={() => {
              dispatch(setURL(url));
              dispatch(setPlaying(true));
              initializeWaveSurfer();
            }}
          ></IonIcon>
        )}
        <div
          ref={waveformRef}
          className={`waveform ${loading ? 'loading' : ''}`}
        >
          {!isInitialized && <div className="flat-line"></div>}
        </div>
      </div>
    );
  },
);

export default Waveform;
