export const CRATE_FILTERS = {
  energy: 'Energy',
  valence: 'Mood',
  danceability: 'Danceability',
  // 'popular'
} as const;

export type CrateFilterType = keyof typeof CRATE_FILTERS;

export const CRATE_FILTER_TYPES = Object.keys(
  CRATE_FILTERS,
) as CrateFilterType[];

export type CrateFilterRange = { upper: number; lower: number };

export type CrateFilter = Record<CrateFilterType, CrateFilterRange>;
