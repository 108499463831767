import { IonButton, IonCol, IonIcon, IonRow, IonToast } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import style from './build-generate-crate.module.scss';
import React, { useState } from 'react';
import { iCrate } from '../../../types/ICrate';
import { CrateService } from '../../../services/Crate.service';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setCreatedFrom,
  setSelectedCrate,
  setSelectedCrateName,
} from '../../../store/slices/crate.slice';

interface GenerateCrateProps {
  buildCrateFn: () => iCrate;
  isBtnDisabled?: boolean;
  disabledMessage?: string;
}

export default function BuildGenerateCrate({
  buildCrateFn,
  isBtnDisabled = true,
  disabledMessage = '',
}: GenerateCrateProps) {
  const btnClass = `${style['generate-crate-btn']} ${
    isBtnDisabled ? style['disabled'] : ''
  }`;
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const saveToMyCrates = (crate: iCrate) => {
    if (crate) {
      CrateService.createMyCrate(crate).then((response) => {
        dispatch(setSelectedCrate(crate));
        dispatch(setCreatedFrom(crate?.createdFrom || ''));
        dispatch(setSelectedCrateName(crate.Title));
        history.push(`/my-crates/crate/${response.id}/${crate.createdFrom}`);
      });
    }
  };

  function generateCrate() {
    if (isBtnDisabled) {
      setErrorToastOpen(true);
    }
    if (!isBtnDisabled) {
      const crateData = buildCrateFn();
      saveToMyCrates(crateData);
    }
  }

  return (
    <div className={style['generate-crate-ctn']}>
      <IonRow className={style['full-width']}>
        <IonCol
          size="12"
          sizeLg="6"
          offsetLg="6"
          className={`${style['align-center']} ${style['full-width']}`}
        >
          <IonButton onClick={generateCrate}>
            Next
            <IonIcon icon={chevronForward} slot="end" />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonToast
        isOpen={errorToastOpen}
        message={
          disabledMessage !== ''
            ? disabledMessage
            : 'Please make sure to provide a Crate Name and have tracks to start building'
        }
        onDidDismiss={() => setErrorToastOpen(false)}
        position="middle"
        duration={5000}
      ></IonToast>
    </div>
  );
}
