import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonCol,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import {
  saveCrateChanges,
  selectCrateState,
  setTrackField,
} from '../../store/slices/crate.slice';
import { AppDispatch } from '../../store/store';

interface EditableKeyFieldProps {
  trackId: string;
  keyVal: string | undefined;
  className?: string;
  textColor?: string;
}

const camelotKeys = Array.from({ length: 12 }, (_, i) => `${i + 1}A`).concat(
  Array.from({ length: 12 }, (_, i) => `${i + 1}B`),
);

export const EditableKeyField: React.FC<EditableKeyFieldProps> = ({
  trackId,
  keyVal,
  className = '',
  textColor = 'dark',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isEditable } = useSelector(selectCrateState);
  const [value, setValue] = useState<string | undefined>(keyVal);

  const handleChange = async (selectedKey: string) => {
    setValue(selectedKey);
    dispatch(
      setTrackField({ trackId, field: 'Key_Camelot', value: selectedKey }),
    );
    await dispatch(saveCrateChanges());
  };

  return (
    <IonRow
      className={className}
      style={{ width: '100%', alignItems: 'center' }}
    >
      <IonCol size="12">
        {isEditable ? (
          <IonItem lines="none">
            {/* Styled select dropdown to look like input */}
            <IonSelect
              value={value}
              placeholder="--"
              onIonChange={(e) => handleChange(e.detail.value)}
              className={className}
              style={{
                textAlign: 'center',
                flex: 1,
                minWidth: '6ch',
                maxWidth: '9ch',
                MozAppearance: 'textfield',
                WebkitAppearance: 'none',
              }}
            >
              {camelotKeys.map((key) => (
                <IonSelectOption key={key} value={key}>
                  {key}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        ) : (
          <IonText color={textColor}>
            <p style={{ textAlign: 'center' }}>{value ?? '--'}</p>
          </IonText>
        )}
      </IonCol>
    </IonRow>
  );
};
