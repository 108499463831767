import { IonInput, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveCrateChanges,
  selectCrateState,
  setTrackField,
} from '../../store/slices/crate.slice';
import { AppDispatch } from '../../store/store';
import { iTrack } from '../../types/ITrack';
import { useToast } from '../ToastManager/useToast';
interface EditableNumberFieldProps {
  trackId: string;
  value: number | undefined;
  field: keyof iTrack;
  className?: string;
  textColor?: string;
}

export const EditableNumberField: React.FC<EditableNumberFieldProps> = ({
  trackId,
  value,
  field,
  className = '',
  textColor = 'dark',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isEditable } = useSelector(selectCrateState);
  const [numValue, setNumValue] = useState<number | undefined>(value);
  const { errorToast } = useToast();
  const handleChange = async (e: CustomEvent<{ value: string | null }>) => {
    const newValue = e.detail.value ?? '';

    // Allow empty value (clears number)
    if (newValue.trim() === '') {
      setNumValue(undefined);
      dispatch(setTrackField({ trackId, field, value: undefined }));
      await dispatch(saveCrateChanges());
      return;
    }

    const num = Number(newValue);
    // Check if the number is within the range of 0 to 400
    if (!isNaN(num) && num >= 0 && num <= 100) {
      setNumValue(num);
      dispatch(setTrackField({ trackId, field, value: num }));
      await dispatch(saveCrateChanges());
    } else {
      errorToast({
        message: 'Please enter a number between 0 and 100',
      });
    }
  };

  return (
    <IonRow className={className} style={{ width: '100%' }}>
      {isEditable ? (
        <IonInput
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          debounce={100}
          value={numValue ?? ''}
          onIonInput={(e) =>
            handleChange(e as CustomEvent<{ value: string | null }>)
          }
          className={className}
          color={textColor}
          style={{ textAlign: 'center', minWidth: '3ch', maxWidth: '6ch' }}
        />
      ) : (
        <IonText color={textColor}>
          <p>{numValue ?? '--'}</p>
        </IonText>
      )}
    </IonRow>
  );
};
