// Based on Breakpoints provided by Ionic utility classes. This ensures we can create responsive styles that adapt in the same way as the utility classes
// https://ionicframework.com/docs/layout/css-utilities#responsive-display-attributes
export const BREAKPOINTS = {
  small: 576,
  medium: 768,
  large: 992,
  xl: 1200,
} as const;

export const BREAKPOINT_KEYS = Object.keys(BREAKPOINTS) as BreakPoint[];

export type BreakPoint = keyof typeof BREAKPOINTS;

export type BreakpointContextProps = {
  currentBreakpoint?: BreakPoint;
  matchesSmall: boolean;
  matchesMediumDown: boolean;
  matchesMediumUp: boolean;
  matchesLargeDown: boolean;
  matchesLargeUp: boolean;
  matchesXL: boolean;
};
