import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonRouterLink,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import {
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
  logOutOutline,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import { IonImg, IonModal } from '@ionic/react';
import { LiaCogSolid } from 'react-icons/lia';
import { useHistory } from 'react-router-dom';
import { on, removeListener } from '../../helper/electron';
import { useIcons } from '../../hooks/useIcons';
import Preferences from '../../pages/preferences/Preferences';
import BeamerTrigger from '../beamer/beamer';
import './Header.scss';

interface HeaderProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const Header: React.FC<HeaderProps> = ({ searchQuery, setSearchQuery }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const { icons } = useIcons();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoForward = () => {
    history.goForward();
  };

  useEffect(() => {
    on('deep-link', (url: string) => {
      console.log('[ON] deep-link', url);
      if (history) {
        if (url.length == 0) {
          url = '/';
        }
        history.push(url);
      } else {
        console.error('Navigate is not defined.');
      }
    });

    return () => {
      removeListener('deep-link');
    };
  }, [history]);

  // TODO: Make forward button go grey when no more forwards to go

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonButtons slot="start">
          <IonImg
            src={
              theme === 'dark' ? icons.chLogoDarkMode : icons.chLogoLightMode
            }
            alt="Logo"
            className={`logo ${theme === 'dark' ? 'dark' : ''}`}
          />
        </IonButtons>
        <IonButtons className="center">
          <IonIcon
            className="navigationButton"
            icon={chevronBackCircleOutline}
            onClick={handleGoBack}
          />
          <IonIcon
            className="navigationButton"
            icon={chevronForwardCircleOutline}
            onClick={handleGoForward}
          />
          <IonSearchbar
            showClearButton="always"
            placeholder="Search Crates or Songs"
            onIonInput={(e) => setSearchQuery(e.target.value || '')}
            onIonClear={() => setSearchQuery('')}
            onIonCancel={() => setSearchQuery('')}
            onIonChange={(e) => setSearchQuery(e.target.value || '')}
            color="light"
            value={searchQuery}
          />
        </IonButtons>
        <IonButtons slot="end">
          {/*<IonButton>*/}
          {/*  <ThemeToggle />*/}
          {/*</IonButton>*/}
          <IonButton onClick={() => setShowModal(true)}>
            <LiaCogSolid size={20} onClick={() => setShowModal(true)} />
          </IonButton>
          <IonModal
            isOpen={showModal}
            onDidDismiss={() => setShowModal(false)}
            className="preferences-modal"
          >
            <Preferences />
          </IonModal>
          {/*<IonRouterLink routerLink="/account">*/}
          {/*  <IonButton shape="round">*/}
          {/*    <IonIcon icon={profileCircle} />*/}
          {/*  </IonButton>*/}
          {/*</IonRouterLink>*/}
          <BeamerTrigger />
          <IonRouterLink routerLink="/logout" className={'signout-button'}>
            <IonButton shape="round">
              <IonIcon icon={logOutOutline} />
            </IonButton>
          </IonRouterLink>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
