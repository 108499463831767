import { IonAccordion, IonAccordionGroup, IonItem } from '@ionic/react';
import { forwardRef } from 'react';
import { iTrack } from '../../../types/ITrack';
import { TrackDetailsRow } from '../TrackDetailsRow';
import style from './style.module.scss';

export const HiddenTracks = forwardRef<
  HTMLDivElement,
  { tracks?: iTrack[]; onDeleteTrack: (track: iTrack) => void }
>(function HiddenTracks({ tracks, onDeleteTrack }, ref) {
  return tracks?.length ? (
    <div ref={ref} className={style['hidden-tracks']}>
      <IonAccordionGroup>
        <IonAccordion>
          <IonItem slot="header" color="transparent">
            <h2 className={style['title']}>
              Filtered Tracks ({tracks?.length ?? 0})
            </h2>
          </IonItem>
          <div slot="content">
            The following tracks do not meet the current filter criteria:
            {tracks.map((track) => (
              <div className="track disabled" key={track.ID}>
                <TrackDetailsRow
                  {...track}
                  disabled={true}
                  onDeleteTrack={() => onDeleteTrack(track)}
                />
              </div>
            ))}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  ) : null;
});
