import { ReturnGroupDto } from '@cratehackers/api-client';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonLabel,
  IonRow,
  IonText,
  IonTextarea,
} from '@ionic/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { run } from '../../../helper/electron';
import { useIcons } from '../../../hooks/useIcons';
import ApiService from '../../../services/Api.service';
import { CrateService } from '../../../services/Crate.service';
import {
  addTrackToCrate,
  fetchCrateById,
  removeTrackFromCrate,
  saveCrateChanges,
  selectCrateState,
  selectFilteredCrateTracks,
  selectIsCrateSaving,
  setCrateTracks,
  setSelectedCrate,
  setSelectedCrateDescription,
  setSelectedCrateGroupId,
  setSelectedCrateName,
  setSelectedCrateStreamingLinks,
  uploadCrateImage,
} from '../../../store/slices/crate.slice';
import {
  clearTrackMatches,
  selectAllMatches,
  selectFirstMatches,
  selectSelectedMatches,
  selectShowMatcher,
  setShowMatcher,
} from '../../../store/slices/track-matcher.slice';
import { fetchTrackById } from '../../../store/slices/track.slice';
import { AppDispatch } from '../../../store/store';
import { iCrate } from '../../../types/ICrate';
import { iHacker } from '../../../types/IHackers';
import { iTrack } from '../../../types/ITrack';
import isElectron from '../../../utils/isElectron';
import { CategoriesEditable } from '../../categories/CategoriesEditable';
import { HackersInline } from '../../hackers/Hackers';
import { useLoading } from '../../RootLoader/useLoading';
import { SavingSpinner } from '../../SavingSpinner/SavingSpinner';
import { useToast } from '../../ToastManager/useToast';
import { HiddenTracks } from '../../tracks/HiddenTracks/HiddenTracks';
import { Recommendations } from '../../tracks/Recommendations';
import SearchComponent from '../../tracks/SearchComponent';
import TrackMatcherControls from '../../tracks/TrackMatcherControls';
import { Tracks } from '../../tracks/Tracks';
import { CrateFilter } from '../CrateFilter/CrateFilter';
import { CrateFilterReorderDialog } from '../CrateFilterReorderDialog/CrateFilterReorderDialog';
import '../Crates.scss';
import { CloudCrates } from './CloudCrates';
import Export from './Export';
import ImageCropper from './ImageCropper';

interface iCrateProps {
  id?: string;
  tracks?: iTrack[];
  withTracks?: boolean;
  sortingAvailable?: boolean;
}

interface ICloudCrateLink {
  name: string;
  link: string;
}

export const Crate: React.FC<iCrateProps> = ({
  id,
  withTracks = false,
  tracks = [],
}) => {
  const { icons, loading } = useIcons();

  // ================= STATE ========================
  /* General Properties */
  const [hackers, setHackers] = useState<iHacker[]>([]);
  const [editingDescription, setEditingDescription] = useState(false);
  const [tempDescription, setTempDescription] = useState<string>('');
  const [crateImageFile, setCrateImageFile] = useState<File | null>(null);

  /* General Properties */
  const [groups, setGroups] = useState<ReturnGroupDto[]>([]);
  const [trackReorderInProgress, setTrackReorderInProgress] = useState<
    iTrack[] | null
  >(null);
  const hiddenTracksRef = useRef<HTMLDivElement>(null);

  // ================= ROUTER ========================
  const matchExport = useRouteMatch('/crate/:id/export');
  const history = useHistory();
  const { successToast, errorToast } = useToast();

  // REDUX
  const dispatch = useDispatch<AppDispatch>();
  const { setIsLoading } = useLoading();
  const {
    isEditable,
    selectedCrate: crate,
    filter: { isFilterActive },
  } = useSelector(selectCrateState);
  const isSaving = useSelector(selectIsCrateSaving);
  const showTrackMatcher = useSelector(selectShowMatcher);
  const selectedMatchesMap = useSelector(selectSelectedMatches());
  const { visible: filteredTracks, hidden } = useSelector(
    selectFilteredCrateTracks,
  );
  const totalMatches = useMemo(
    () =>
      Object.values(selectedMatchesMap).reduce(
        (sum, paths) => sum + paths.length,
        0,
      ),
    [selectedMatchesMap],
  );

  const handleTitleChange = async (e: any) => {
    try {
      await dispatch(setSelectedCrateName(e.target.value));
      await dispatch(saveCrateChanges());
      successToast({
        message: `Changed title to ${crate?.Title}`,
      });
    } catch (error) {
      errorToast({
        message: `Unable to change title to ${crate?.Title}`,
      });
    }
  };

  const handleDescriptionChange = async (description: string) => {
    await dispatch(setSelectedCrateDescription(description));
  };

  const handleSaveDescriptionChange = async () => {
    try {
      await dispatch(saveCrateChanges());
      successToast({
        message: `Changed description for ${crate?.Title}`,
      });
    } catch (error) {
      errorToast({
        message: `Unable to change description for ${crate?.Title}`,
      });
    }
  };

  const handleImageChange = async (file: File | null) => {
    try {
      setCrateImageFile(file);
      if (file) {
        await dispatch(uploadCrateImage(file)); // TODO: Handle the upload logic
      } else {
        errorToast({ message: `No image file was added` });
      }
      await dispatch(saveCrateChanges());
      successToast({
        message: `Changed image for ${crate?.Title}`,
      });
    } catch (error) {
      errorToast({
        message: `Unable to change image for ${crate?.Title}`,
      });
    }
  };

  const handleStreamingLinkUpdate = async (streamingLinks: Partial<iCrate>) => {
    try {
      await dispatch(setSelectedCrateStreamingLinks(streamingLinks));
      await dispatch(saveCrateChanges());
      successToast({
        message: `Updated streaming links for ${crate?.Title}`,
      });
    } catch (error) {
      errorToast({
        message: `Unable to change streaming links for ${crate?.Title}`,
      });
    }
  };

  // TODO: Probably eventually allow multiple groups?
  const handleGroupIdChange = async (groupId: number, action: string) => {
    const newGroupId = action === 'add' ? groupId : 0;
    try {
      await dispatch(setSelectedCrateGroupId(newGroupId));
      await dispatch(saveCrateChanges());
      successToast({
        message: `Changed group for ${crate?.Title}`,
      });
    } catch (error) {
      errorToast({
        message: `Unable to change group for ${crate?.Title}`,
      });
    }
  };

  // Fetches Crate from the API using its ID
  useEffect(() => {
    const fetchPromise = id ? dispatch(fetchCrateById(id)) : undefined;
    if (fetchPromise) {
      setIsLoading(true);
      fetchPromise.then((response) => {
        if (fetchCrateById.fulfilled.match(response) && response.payload) {
          // Set fetched crate into Redux state
          dispatch(setSelectedCrate(response.payload));
          dispatch(clearTrackMatches());
          setIsLoading(false);
        }
      });
    }
    // On cleanup abort the request if it's in progress
    return () => fetchPromise?.abort();
  }, [id, dispatch, setIsLoading]);

  // Load the users available groups
  useEffect(() => {
    const chAPI = ApiService.getApiClient();
    chAPI.usersControllerGetUsersGroups().then((groups) => {
      setGroups(groups);
    });
  }, []);

  // Load tracks and hackers from crate data in Redux state
  useEffect(() => {
    if (crate?.Hacker) {
      setHackers(crate.Hacker);
    }
  }, [crate]);

  // Saves the track file paths any time one is selected or de-selected
  useEffect(() => {
    if (isElectron() && Object.keys(selectedMatchesMap).length > 0) {
      run('crates/save-track-file-paths', {
        crate_id: id,
        filePaths: selectedMatchesMap,
      });
    }
  }, [selectedMatchesMap, id]);

  const handleDeleteTrack = async (track: iTrack) => {
    try {
      await dispatch(removeTrackFromCrate(track.ID));
      await dispatch(saveCrateChanges());
      successToast({
        message: `Removed ${track.Title} from ${crate?.Title}`,
      });
    } catch (error) {
      errorToast({
        message: `Unable to remove ${track.Title} from ${crate?.Title}`,
      });
    }
  };

  const handleReorderTracks = async (newTrackOrder: iTrack[]) => {
    if (hidden?.length) {
      setTrackReorderInProgress(newTrackOrder);
      return;
    }
    saveReorderedTracks(newTrackOrder);
  };

  const saveReorderedTracks = async (newTrackOrder: iTrack[]) => {
    const originalTrackOrder = filteredTracks;
    await dispatch(setCrateTracks(newTrackOrder));
    try {
      await dispatch(saveCrateChanges());
    } catch (error) {
      errorToast({
        message: `Unable to save changes`,
      });
      // Revert changes if unsuccessful
      await dispatch(setCrateTracks(originalTrackOrder));
    }
  };

  const handleAddTrackToCrate = async (trackId: string) => {
    const trackInCrate = crate?.Tracks?.find(
      (track) => track.SpotifyID === trackId,
    );

    // Avoids a user adding a track that is already in the current Crate
    if (trackInCrate) {
      errorToast({
        message: `${trackInCrate.Title} already exists in ${crate?.Title}`,
      });
      return;
    }
    try {
      const track = await dispatch(fetchTrackById(trackId));
      if (fetchTrackById.fulfilled.match(track) && track.payload) {
        await dispatch(addTrackToCrate(track.payload));
        await dispatch(saveCrateChanges());
      }
    } catch (error) {
      console.error('Error adding track:', error); // Handle any potential errors
      errorToast({ message: 'Unable to add track' });
    }
  };

  // Saves a crate to a user's "My Crates"
  const saveToMyCrates = useCallback(() => {
    setIsLoading(true);
    if (crate) {
      CrateService.createMyCrate({
        ...crate,
      }).then(() => {
        history.push('/my-crates');
        setIsLoading(false);
      });
    }
  }, [crate, history, setIsLoading]);

  // Scrolls the hidden tracks list into view
  const scrollFilteredTracksIntoView = () =>
    hiddenTracksRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  return (
    <>
      {/* Saving spinner appears any time we're persisting track changes to the API */}
      <SavingSpinner isOpen={isSaving} />
      <IonGrid
        data-id={crate?._id}
        className={`crate-card` + (isFilterActive ? ' filter-active' : '')}
      >
        <IonRow>
          <IonCol size="12" sizeMd="6" sizeLg="2">
            {crate?.Image ? ( // TODO: Change this when we are supporting images again
              <ImageCropper
                editable={false} // TODO :Change this to isEditable when we are supporting images again
                onImageChange={handleImageChange}
                currentImageUrl={crate.Image}
              />
            ) : (
              <IonImg alt={crate?.Title} src={icons.flameLogo} />
            )}
          </IonCol>

          <IonCol size="12" sizeMd="6" sizeLg="5">
            <IonGrid>
              <IonRow>
                <div className="categories-container">
                  <IonLabel className="categories">
                    <CategoriesEditable />
                  </IonLabel>
                </div>
              </IonRow>
              <IonRow>
                <div style={{ width: '100%', height: '100%' }}>
                  {isEditable ? (
                    <div style={{ width: '100%', height: '100%' }}>
                      <IonLabel position="stacked">Crate Title</IonLabel>
                      <IonInput
                        value={crate?.Title}
                        onIonChange={handleTitleChange}
                      />{' '}
                    </div>
                  ) : (
                    <IonLabel className="title">
                      <h1>{crate?.Title}</h1>
                    </IonLabel>
                  )}
                </div>
              </IonRow>
              <IonRow>
                <IonLabel className="hackers">
                  <HackersInline hackers={hackers} />
                  {groups.map((group) => {
                    if (group.id === crate?.groupId) {
                      return (
                        <>
                          <IonText>, {group.name}</IonText>
                          {isEditable && (
                            <IonButton
                              size="small"
                              onClick={() =>
                                handleGroupIdChange(group.id, 'remove')
                              }
                              fill="clear"
                            >{`Remove from ${group.name} crates`}</IonButton>
                          )}
                        </>
                      );
                    } else {
                      return (
                        <>
                          {isEditable && (
                            <IonButton
                              key={`group-${group.id}`}
                              size="small"
                              onClick={() =>
                                handleGroupIdChange(group.id, 'add')
                              }
                              fill="clear"
                            >
                              {`Add to ${group.name} crates`}
                            </IonButton>
                          )}
                        </>
                      );
                    }
                  })}
                </IonLabel>
              </IonRow>
              <IonRow className="description">
                <IonCol size="12">
                  <IonRow>
                    {!editingDescription ? (
                      <IonLabel class="ion-text-wrap">
                        {crate?.Description}
                      </IonLabel>
                    ) : (
                      isEditable && (
                        <>
                          <IonLabel position="stacked">Description</IonLabel>
                          <IonTextarea
                            fill="outline"
                            value={crate?.Description}
                            onIonChange={(e) =>
                              handleDescriptionChange(e.target.value!)
                            }
                          />
                        </>
                      )
                    )}
                  </IonRow>
                  <IonRow>
                    {isEditable && (
                      <IonButton
                        fill="clear"
                        color="dark"
                        onClick={() => {
                          if (editingDescription) {
                            handleSaveDescriptionChange();
                          }
                          setEditingDescription(!editingDescription);
                        }}
                      >
                        {editingDescription
                          ? 'Save Description'
                          : 'Edit Description'}
                      </IonButton>
                    )}
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size="12" size-md="12" size-lg="5">
            {crate && (
              <CloudCrates
                editable={isEditable}
                crateData={crate}
                onUpdateStreamingLinks={handleStreamingLinkUpdate}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid className={`actions-container`}>
        <IonRow className="crate-actions-row">
          <IonCol size="12" sizeMd="5" sizeLg="8">
            <TrackMatcherControls
              showTrackMatcher={showTrackMatcher}
              onToggleTrackMatcher={(e) =>
                dispatch(setShowMatcher(e.detail.checked))
              }
              onSelectFirstMatches={() => dispatch(selectFirstMatches())}
              onSelectAllMatches={() => dispatch(selectAllMatches())}
              totalMatches={totalMatches}
            />
          </IonCol>

          <IonCol size="12" sizeMd="7" sizeLg="4">
            <div className="ion-float-end action-buttons">
              {/*TODO: Update this button with group language*/}
              {crate && crate.Type !== 'User' && (
                <IonButton
                  className="save-to-my-crates ion-text-capitalize"
                  fill="outline"
                  color="primary"
                  onClick={saveToMyCrates}
                >
                  <IonText
                    className="ion-text-capitalize ion-text-wrap"
                    color="dark"
                  >
                    {`Save To My Crates`}
                  </IonText>
                </IonButton>
              )}

              {isElectron() && (
                <Export
                  crateName={crate?.Title || ''}
                  crateId={id}
                  tracks={selectedMatchesMap}
                  trackInfo={filteredTracks}
                  hacker={
                    hackers?.map((hacker) => hacker.name).join(', ') || ''
                  }
                  forceShowExport={!!matchExport}
                  cloudCrateList={generateCloudCrateMap(crate)}
                />
              )}
            </div>
          </IonCol>
        </IonRow>
        {isEditable && (
          <CrateFilter
            onShowFilteredTracks={scrollFilteredTracksIntoView}
            onMagicSort={handleReorderTracks}
          />
        )}
      </IonGrid>
      {withTracks && (
        <>
          <Tracks
            editable={isEditable}
            showTrackMatcher={showTrackMatcher}
            tracks={filteredTracks}
            onDeleteTrack={handleDeleteTrack}
            onReorderTracks={handleReorderTracks}
          />
          <HiddenTracks
            ref={hiddenTracksRef}
            tracks={hidden}
            onDeleteTrack={handleDeleteTrack}
          />
        </>
      )}

      {isEditable && (
        <>
          <h2>Search</h2>
          <SearchComponent handleClick={handleAddTrackToCrate} />
        </>
      )}
      {filteredTracks?.length > 0 && filteredTracks?.at(-1) && isEditable && (
        <Recommendations
          ID={filteredTracks?.at(-1)?.SpotifyID || ''}
          Artist={filteredTracks?.at(-1)?.Artist || ''}
          Title={filteredTracks?.at(-1)?.Title || ''}
          Key_Camelot={filteredTracks?.at(-1)?.Key_Camelot || undefined}
          keyMatches={filteredTracks?.at(-1)?.keyMatches || undefined}
          showAddTrack={isEditable}
          addTrackHandler={handleAddTrackToCrate}
        />
      )}
      <CrateFilterReorderDialog
        isOpen={!!trackReorderInProgress}
        onDismiss={() => setTrackReorderInProgress(null)}
        numHiddenTracks={hidden?.length ?? 0}
        onConfirm={() => {
          if (trackReorderInProgress) {
            saveReorderedTracks(trackReorderInProgress.concat(hidden));
          }
          setTrackReorderInProgress(null);
        }}
      />
    </>
  );
};

const generateCloudCrateMap = (crate: iCrate | null) => {
  const cloudCrateMap: ICloudCrateLink[] = [];

  if (crate?.StreamDeezer) {
    cloudCrateMap.push({
      name: 'deezer',
      link: crate.StreamDeezer,
    });
  }

  if (crate?.StreamTidal) {
    cloudCrateMap.push({
      name: 'tidal',
      link: crate.StreamTidal,
    });
  }

  if (crate?.StreamSoundcloud) {
    cloudCrateMap.push({
      name: 'soundcloud',
      link: crate.StreamSoundcloud,
    });
  }

  if (crate?.StreamAppleMusic) {
    cloudCrateMap.push({
      name: 'apple',
      link: crate.StreamAppleMusic,
    });
  }

  if (crate?.StreamBeatsource) {
    cloudCrateMap.push({
      name: 'beatsource',
      link: crate.StreamBeatsource,
    });
  }

  if (crate?.StreamSpotify) {
    cloudCrateMap.push({
      name: 'spotify',
      link: crate.StreamSpotify,
    });
  }
  return cloudCrateMap;
};
