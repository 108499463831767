import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonInput, IonRow, IonText } from '@ionic/react';
import {
  saveCrateChanges,
  selectCrateState,
  setTrackField,
} from '../../store/slices/crate.slice';
import { iTrack } from '../../types/ITrack';
import { AppDispatch } from '../../store/store';

interface EditableTextFieldProps {
  trackId: string;
  text: string;
  field: keyof iTrack;
  className?: string;
  textColor?: string;
}

export const EditableTextField: React.FC<EditableTextFieldProps> = ({
  trackId,
  text,
  field,
  className = '',
  textColor = 'dark',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isEditable } = useSelector(selectCrateState);
  const [value, setValue] = useState<string>(text);

  const handleChange = async (e: CustomEvent<{ value: string | null }>) => {
    const newValue = e.detail.value ?? '';
    setValue(newValue);
    dispatch(setTrackField({ trackId, field, value: newValue }));
    await dispatch(saveCrateChanges());
  };

  return (
    <IonRow className={className} style={{ width: '100%' }}>
      {isEditable ? (
        <IonInput
          value={value}
          onIonInput={(e) =>
            handleChange(e as CustomEvent<{ value: string | null }>)
          }
          label={field.toUpperCase()}
          labelPlacement="floating"
          debounce={500}
          className={className}
          color={textColor}
        />
      ) : (
        <IonText color={textColor}>
          <p>{value}</p>
        </IonText>
      )}
    </IonRow>
  );
};
