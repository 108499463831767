export const getCroppedImage = async (
  imageSrc: string,
  crop: { x: number; y: number; width: number; height: number },
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous'; // Prevent CORS issues
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        console.error('❌ Canvas context not available.');
        reject(new Error('Canvas context not available'));
        return;
      }

      // Use the crop values directly (since they are already in pixels)
      const cropX = Math.max(0, crop.x);
      const cropY = Math.max(0, crop.y);
      const cropWidth = Math.min(image.naturalWidth - cropX, crop.width);
      const cropHeight = Math.min(image.naturalHeight - cropY, crop.height);

      // Ensure crop is valid
      if (cropWidth <= 0 || cropHeight <= 0) {
        reject(new Error('Invalid crop dimensions.'));
        return;
      }

      // Set canvas size to match cropped area
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      // Fix PNG transparency issues
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight, // Source crop (x, y, width, height)
        0,
        0,
        cropWidth,
        cropHeight, // Destination in canvas
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('❌ Failed to generate cropped image blob.');
          reject(new Error('Failed to generate cropped image blob.'));
          return;
        }

        const file = new File([blob], 'cropped_image.jpg', {
          type: 'image/jpeg',
        });
        resolve(file);
      }, 'image/jpeg');
    };

    image.onerror = () => {
      reject(new Error('Error loading image for cropping.'));
    };
  });
};
